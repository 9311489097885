import Table from "../../components/finalTable/Table";
import { sliderColumns, slider_val } from "../../data/columns";
import { sliderData } from '../../data/inputData'
import useToggle, { useToggleTabs } from "../../hooks/useToggle";
import SliderModal from '../../components/modalRight/ModalRight'

const Slider = () => {
    const { tabNumber, setTabNumber } = useToggleTabs()
    const { toggleModal, setToggleModal } = useToggle()

    return (
        
        <div style={{ padding: "1rem" }}>
            <div className="col-12 mt-3">
                <div className="col-12 mt-3">
                    <Table
                        title="Sliders"
                        url="internet/slider/get"
                        deleteUrl="internet/slider/delete/"
                        columns={sliderColumns()}
                        valuesData={slider_val}
                        // modalID="sliderModal"
                        // modalTitle="Update Orders"
                        category="slider"
                        method="put"
                        noFormData={true}
                        actions={["delete"]}
                        tabNumber={tabNumber}
                        // setTabNumber={setTabNumber}
                        setToggleModal={setToggleModal}
                        actionLabel="Create"
                        cardData={[
                            { title: "Total Sliders", background: "blueviolet", color: "#fff" },
                        ]}
                    />
                    <SliderModal
                        toggleModal={toggleModal}
                        setToggleModal={setToggleModal}
                        title="Add Slider"
                        inputsArr={sliderData}
                        // inputData={inputData}
                        // setInputData={setInputData}
                        width="100%"
                        actionLabel="Create"
                        url="internet/slider/create"
                        method="post"
                        noFormData={false}
                        picField={["image"]}
                        type='slider'
                    />
                </div>
            </div>
        </div>

    )
}

export default Slider