
import './tab.css'

const Tabs = ({ tabList, tabNumber, setTabNumber }) => {

    return (
        <div className='tab_wrapper'>
            {tabList.map((tabName, i) =>
                <button style={{ background: tabNumber === i && "blueviolet", color: tabNumber === i && "#fff" }} onClick={() => setTabNumber(i)} key={i}>{tabName}
                </button>
            )}
        </div>
    )
}

export default Tabs