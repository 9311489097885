import Navbar from "./Navbar"
// import Search from "./Search"
import Chats from "./Chats"

const Sidebar = ({ data, setSingleUser }) => {
    return (
        <div className="sidebar" style={{ overflowY: "auto" }}>
            <Navbar />
            <Chats data={data} setSingleUser={setSingleUser} />
        </div>
    );
};

export default Sidebar;
