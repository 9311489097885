import "./style.css";
import useFormFn from "./functions";
import { useEffect, useState } from "react";
import Form from "../form/Form";


const StaffForm = ({
    toggleModal,
    setToggleModal,
    //   inputData, setInputData,     
    actionLabel, url, method, inputsArr, title, width, noFormData, picField, type
}) => {

    const [inputData, setInputData] = useState({})
    const [showModal, setShowModal] = useState(toggleModal);
    const { isLoading, fn_handleInputData, fn_handleSubmitForm } = useFormFn(inputData, setInputData, url, method, noFormData, picField, type)
    useEffect(() => {
        setShowModal(toggleModal);
    }, [toggleModal]);

    function fn_ToggleForm(e) {
        const targetElemClass = e.target?.className;
        if (targetElemClass === "staffForm_overlay") setToggleModal(false);
    }

    if (!toggleModal) {
        return null
    }
    return (
        <>
            <div className="staffForm_overlay" onClick={fn_ToggleForm}></div>
            <div
                className="staffForm_wrapper"
                style={{ width: showModal ? "350px" : 0, opacity: showModal ? 1 : 0 }}
            >

                <Form
                    inputsArr={inputsArr}
                    title={title}
                    width={width}
                    setToggleModal={setToggleModal}
                    actionLabel={actionLabel}
                    onSubmit={fn_handleSubmitForm}
                    onChange={fn_handleInputData}
                    showModal={showModal}
                    isLoading={isLoading}
                    inputData={inputData}
                />
            </div>

        </>
    );
};

export default StaffForm;
