

const Chats = ({ data, setSingleUser }) => {


  return (
    <div className="chats">
      <div
        className="userChat"
      >
        {data?.map((item, i) =>
          <div className="userChatInfo" key={i} onClick={() => setSingleUser(item)}>
            <img src={item.selfie} alt="" />
            <span style={{ marginLeft: "0.3rem" }}>{item.firstName}</span>

          </div>
        )}

      </div >
    </div>
  );
};

export default Chats;
