// import { useState } from 'react'
import SideModal from '../../components/modalRight/ModalRight'
import { userData } from '../../data/inputData'
// import { userFields, updateUserFields } from '../../data/inputFields'
import { userColumns } from '../../data/columns'
import useToggle from '../../hooks/useToggle'
import AllUsers from '../pakages/AllPackages'
import { updateUser } from '../../data/inputData'
import useCustomContext from '../../hooks/useContext'

const User = () => {
    // const { toggleModal, setToggleModal } = useToggle()
    // const [inputData, setInputData] = useState(userFields)



    return (
        <>

            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>

                <AllUsers
                    title="All Users"
                    titleSecond="Approved Users"
                    titleThird="Pending Users"
                    modalTitle="Update User"
                    hasTabs={true}
                    // modalName="userModal"
                    url="internet/user/get"
                    columns={userColumns}
                    // setToggleModal={setToggleModal}
                    id="userModal"
                    category="user"
                    // fieldsToUpdate={updateUserFields}
                    updateUrl="internet/user/updatepass"
                    deleteUrl="internet/user/delete/"
                    method="put"
                    // actionLabel="Create User"
                    noFormData={false}
                    updateInputArr={updateUser}
                    pendingUsers={true}
                    filterData={true}
                    actions={['view']}
                    filterAs={['user', true, false]}
                    filterBy={['registerAs', 'status', "status"]}
                    navigateUrl="/verification/"
                    cardData={[
                        { title: "All Users", background: "blueviolet", color: "#fff" },
                        { title: "Approved Users", background: "blueviolet", color: "#fff" },
                        { title: "Pending Users", background: "blueviolet", color: "#fff" }
                    ]
                    }
                />
            </div>



        </>
    )
}

export default User