import Table from "../../components/finalTable/Table";
import { ordersColumns, orders_val } from "../../data/columns";
import { updateBills } from "../../data/inputData";
import useToggle, { useToggleTabs } from "../../hooks/useToggle";


const Orders = () => {
    const { tabNumber, setTabNumber } = useToggleTabs()

    return (
        <div style={{ padding: "1rem" }}>
            <div className="col-12 mt-3">
                <div className="col-12 mt-3">
                    <Table
                        title="All Orders"
                        url="internet/buy/get"
                        updateUrl="internet/buy/update/"
                        deleteUrl=""
                        columns={ordersColumns("hideActions")}
                        valuesData={orders_val}
                        modalID="ordersModal"
                        modalTitle="Update Orders"
                        category="orders"
                        method="put"
                        noFormData={true}
                        // updateInputArr={updateBills}
                        // actions={["update"]}
                        tabNumber={tabNumber}
                        setTabNumber={setTabNumber}
                        // setToggleModal={setToggleModal}
                        // actionLabel="Create Order"
                        cardData={[
                            { title: "Total Orders", background: "blueviolet", color: "#fff" },
                        ]}
                    />
                </div>
            </div>
        </div>

    )
}

export default Orders