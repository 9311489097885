
import { billsColumns } from '../../data/columns'
import AllBills from '../pakages/AllPackages'



const Bills = () => {



    return (
        <>

            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>

                <AllBills
                    title="All Bills"
                    url="internet/buy/get"
                    columns={billsColumns("noAction")}
                    id="billModal"
                    category="bill"

                    method="put"

                    tabList={[]}
                    filterAs={[undefined, true, false]}
                    filterBy={[undefined, 'packageStatus', 'packageStatus']}
                    cardData={[
                        { title: "All Bills", background: "blueviolet", color: "#fff" },
                        { title: "Paid Bills", background: "blueviolet", color: "#fff" },
                        { title: "Unpaid Bills", background: "blueviolet", color: "#fff" },
                    ]}
                />
            </div>



        </>
    )
}

export default Bills