import { useState } from 'react'
import { fn_axiosDelete } from '../hooks/useAxios'
const useSearch = (orignalArray = [], toBeSearch, setForcedRender = () => { }) => {

    const [searchResult, setSearch] = useState(null)
    const [splicedArr, setSplicedArr] = useState(null)
    function fn_searchData(e) {
        const { value } = e.target
        if (value.length === 0) {
            // return setSearch(orignalArray)
            return setSearch(null)
        }
        const filteredData = orignalArray.filter((item) => {
            if (typeof item[toBeSearch] === 'string') {
                return item[toBeSearch].toLowerCase().includes(value)
            }
        })
        setSearch(filteredData)
    }
    async function fn_deleteData(id, url) {
        try {
            await fn_axiosDelete(url)
            if (splicedArr?.length > 0) {
                const tempArr = [...splicedArr]
                const findIndex = tempArr.findIndex(i => i.id === id)
                tempArr.splice(findIndex, 1)
                setSplicedArr(tempArr)
            } else {
                const tempArr = [...orignalArray]
                const findIndex = orignalArray.findIndex(i => i.id === id)
                tempArr.splice(findIndex, 1)
                setSplicedArr(tempArr)
            }

            // orignalArray.splice(findIndex, 1)
            setForcedRender(prev => !prev)

        } catch (error) {

            console.log('error')
        }
    }
    return {
        fn_searchData,
        fn_deleteData,
        searchResult,
        splicedArr
    }
}

export default useSearch