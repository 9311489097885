import Table from "../../components/Table/main/Table";
import useFetcher from '../../hooks/useFetcher'

import { useState } from "react";
import { useToggleTabs } from "../../hooks/useToggle";
import TableHeader from "../../components/Table/header/TableHeader";
import Card from '../../components/newCard/Card'
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";

const SimpleTable = ({ title, titleSecond, titleThird, filterData, filterAs, filterBy, hasTabs, tabList,
    modalTitle, modalName, modalData, actionLabel, url, deleteUrl, columns, setToggleModal,
    id, category, updateInputArr, updateUrl, method, noFormData, pendingUsers, actions, selectName,
    cardData, navigateUrl

}) => {

    const rows = useFetcher(url)
    const [selectedRow, setSelectedRow] = useState({})
    const [inputData, setInputData] = useState({})
    const { tabNumber, setTabNumber } = useToggleTabs()



    console.log(inputData);


    return (
        <>

            {/* <Card key={i} title={item.title} count={fn_filterData(rows, filterBy[i], filterAs[i])} icon={ArrowOutwardIcon} background={item.background} */}
            <div style={{ padding: "1rem" }}>
                <div className="row">

                    {cardData?.[0] && <Card title={cardData[0]?.title} count={cardData[0]?.count ?? rows.filter(item => item[filterBy?.[0]] === filterAs?.[0]).length} icon={ArrowOutwardIcon} background={cardData[0]?.background}
                        color={cardData[0]?.color} />
                    }
                    {cardData?.[1] && <Card title={cardData[1]?.title}
                        // count={cardData[1]?.count ?? category === 'user' ? rows.filter(item => item[filterBy[1]] === filterAs[1] && item[filterBy[0]] === filterAs[0]).length : rows.filter(item => item[filterBy[1]] === filterAs[1]).length}
                        count={cardData[1]?.count ?? (category === 'user' ? rows.filter(item => item[filterBy[1]] === filterAs[1] && item[filterBy[0]] === filterAs[0]).length : rows.filter(item => item[filterBy[1]] === filterAs[1]).length)}
                        icon={ArrowOutwardIcon} background={cardData[1]?.background}
                        color={cardData[1]?.color} />
                    }
                    {cardData?.[2] && <Card title={cardData[2]?.title}
                        count={cardData[2]?.count ?? (category === 'user' ? rows.filter(item => item[filterBy[2]] === filterAs[2] && item[filterBy[0]] === filterAs[0]).length : rows.filter(item => item[filterBy[2]] === filterAs[2]).length)}
                        icon={ArrowOutwardIcon} background={cardData[2]?.background}
                        color={cardData[2]?.color} />
                    }


                </div>
                <div style={{ marginTop: "1rem" }}>
                    <TableHeader
                        actionLabel={actionLabel}
                        hasTabs={hasTabs}
                        tabNumber={tabNumber}
                        setTabNumber={setTabNumber}
                        setToggleModal={setToggleModal}
                        tabList={tabList}
                        title={title}
                        titleSecond={titleSecond}
                        titleThird={titleThird}
                        category={category}
                    />

                    <div className="col-12 mt-3">
                        {tabNumber === 0 &&
                            <Table
                                modalTitle={modalTitle}
                                modalName={modalName}
                                modalData={modalData ?? rows}
                                rows={(category === "shop" || category === "packages" || category === "user" || category === "expenses") ? rows.filter((item) => item[filterBy[0]] === filterAs[0]) : rows}
                                type={category}
                                tableHead={columns}
                                hideCol={true}
                                id={id}
                                inputData={inputData}
                                setInputData={setInputData}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                url={updateUrl}
                                deleteUrl={deleteUrl}
                                method={method}
                                noFormData={noFormData}
                                updateInputArr={updateInputArr}
                                actions={actions}
                                selectName={selectName}
                                navigateUrl={navigateUrl}

                            />
                        }
                        {tabNumber === 1 &&
                            <Table
                                modalTitle={modalTitle}
                                modalName={modalName}
                                rows={filterData ? rows.filter((item) => item[filterBy[category === "expense" ? 1 : 0]] === filterAs[category === "expenses" ? 1 : 0]) : rows}
                                type={category}
                                hideCol={true}
                                tableHead={columns}
                                id={id}
                                inputData={inputData}
                                setInputData={setInputData}
                                url={updateUrl}
                                deleteUrl={deleteUrl}
                                method={method}
                                // noFormData={noFormData}
                                updateInputArr={updateInputArr}
                                actions={actions}
                            />
                        }
                        {tabNumber === 2 &&
                            <Table
                                modalTitle={modalTitle}
                                modalName={modalName}
                                rows={filterData ? rows.filter((item) => category === 'expenses' ? item[filterBy[2]] === filterAs[2] : item[filterBy[1]] !== filterAs[1]) : rows}
                                type={category}
                                tableHead={columns}
                                id={id}
                                inputData={inputData}
                                setInputData={setInputData}
                                url={updateUrl}
                                deleteUrl={deleteUrl}
                                method={method}
                                noFormData={noFormData}
                                updateInputArr={updateInputArr}
                                pendingUsers={pendingUsers}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRow}
                                actions={actions}
                            />
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default SimpleTable