import { useState } from 'react'

const useToggle = () => {
    const [toggleModal, setToggleModal] = useState(false)
    return { toggleModal, setToggleModal }
}
export const useToggleTabs = () => {
    const [tabNumber, setTabNumber] = useState(0)
    return { tabNumber, setTabNumber }
}

export default useToggle