import React, { useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
const ImageTab = () => {
  const [activeId, setActiveId] = useState(null);
  function activeFn(id) {
    setActiveId(id);
  }
  return (
    <div>
      <div>
        <h4>Top Referral Sources</h4>
        <IconButton>
          <MoreVertIcon />
        </IconButton>
      </div>
      <p className="head_para">82% Activity Growth</p>
      <div className="image_container">
        {[1, 2, 3].map((item) => (
          <div key={item} style={{ border: item === activeId && "2px solid #666CFF" }} onClick={() => activeFn(item)}>
            <img alt="" src={require("../../../images/watch.png")} />
          </div>
        ))}

        <div>
          <AddIcon />
        </div>
      </div>
    </div>
  );
};

export default ImageTab;
