import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link } from "react-router-dom";
import './style.css'

const TableActions = ({ params, navigateUrl, deleteUrl, fn_handleDelete, setSingleRow, actionType, id }) => {

    return (

        <div className="cellAction">
            {actionType.includes("viewAction") && <Link to={`${navigateUrl}/${params.row?.id}`}
                style={{ textDecoration: "none" }}>
                <div className="viewButton">
                    <VisibilityIcon fontSize="small" />
                </div>
            </Link>
            }
            {actionType.includes("deleteAction") &&
                <div className="deleteButton" onClick={() => fn_handleDelete(params.row?.id, deleteUrl + params.row?.id)}>
                    <DeleteIcon fontSize="small" />
                </div>
            }
            {actionType.includes("updateAction") &&
                <div className="editButton" onClick={() => setSingleRow(params?.row)} >
                    <EditIcon fontSize="small" data-bs-toggle="modal"
                        data-bs-target={"#" + id} />
                </div>
            }
        </div>

    )
}

export default TableActions