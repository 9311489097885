import { paymentColumns } from '../../data/columns'
import { updatePayment } from '../../data/inputData'
import AllPayments from '../pakages/AllPackages'



const Payments = () => {

    return (
        <>

            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>

                <AllPayments
                    title="All Payments"
                    titleSecond="Approved Payments"
                    titleThird="Pending Payments"
                    modalTitle="Update Payment Status"
                    hasTabs={true}
                    url="internet/topup/get"
                    columns={paymentColumns}
                    id="paymentModal"
                    category="payment"
                    updateUrl="internet/topup/update/"
                    deleteUrl="internet/topup/delete/"
                    method="put"
                    noFormData={false}
                    // pendingUsers={true}
                    tabList={["All", "Approved", "Pending"]}
                    selectName="method"
                    actions={['update', 'delete']}
                    filterData={true}
                    filterAs={[undefined, true, false]}
                    filterBy={[null, "status", "status"]}
                    updateInputArr={updatePayment}
                    cardData={[
                        { title: "Total Methods", background: "blueviolet", color: "#fff" },
                        { title: "Approved Methods", background: "blueviolet", color: "#fff" },
                        { title: "Pending Methods", background: "blueviolet", color: "#fff" },
                    ]}

                />
            </div>


        </>
    )
}

export default Payments