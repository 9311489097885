import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
const Slider = ({ data, children: Children }) => {
  return (
    <Swiper
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      onSlideChange={() => console.log("slide change")}
      onSwiper={(swiper) => console.log(swiper)}
    >
      {data?.map((item, i) => {
        return (
          <SwiperSlide key={i}>
            <Children
              title={item.title}
              subTitle={item.title}
              profitLoss={item.profitLoss}
              headerText={item.headerText}
              products={item.products}
              // image={item?.image}
              hideIcon
            />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default Slider;
