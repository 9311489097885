export const pakagesInputData = [
    { title: "Package name", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Description", name: "description", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Duration", name: "duration", type: "number", required: true, errorMsg: "Required" },
    { title: "Bonus Days", name: "bonusDays", type: "number", required: true, errorMsg: "Required" },
    { title: "Price (Rs)", name: "price", type: "number", required: true, errorMsg: "Required" },
    { title: "Discounts (Rs)", name: "discount", type: "number", required: true, errorMsg: "Required" },
    { title: "Internet Speed", name: "totalMb", fields: ["4", "6", "10"], select: true, required: true, errorMsg: "Required", lastOne: "totalMb" },
]
export const shopInputData = [
    { title: "Product name", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Product Description", name: "description", type: "text", required: true, errorMsg: "Required" },
    { title: "Price (Rs)", name: "price", type: "number", required: true, errorMsg: "Required" },
    { title: "Discounts (Rs)", name: "discount", type: "number", required: true, errorMsg: "Required" },
    { title: "Product Pic", name: "image", type: "file", required: true, errorMsg: "Required", lastOne: "image" },



]
export const staffData = [
    { title: "First Name", name: "firstName", type: "text", required: true, errorMsg: "Required" },
    { title: "Last Name", name: "lastName", type: "text", required: true, errorMsg: "Required" },
    { title: "Email", name: "email", type: "text", required: true, errorMsg: "Required" },
    { title: "Phone #", name: "phone", type: "text", required: true, errorMsg: "Required" },
    {
        title: "Password", name: "password", type: "password",
        // pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
        required: true, errorMsg: "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!"
    },
    { title: "Confirm Password", name: "cPassword", type: "password", required: true, errorMsg: "Required" },
    { title: "Profile", name: "selfie", type: "file", required: true, errorMsg: "Required" },
    { title: "Register As", name: "registerAs", fields: ["User", "Staff"], select: true, required: true, errorMsg: "Required" },
    { title: "Address", name: "address", type: "text", required: true, errorMsg: "Required", lastOne: "address" },

]
export const userData = [
    { title: "First Name", name: "firstName", type: "text", required: true, errorMsg: "Required" },
    { title: "Last Name", name: "lastName", type: "text", required: true, errorMsg: "Required" },
    { title: "Email", name: "email", type: "text", required: true, errorMsg: "Required" },
    { title: "Phone #", name: "phone", type: "text", required: true, errorMsg: "Required" },
    { title: "Password", name: "password", type: "password", required: true, errorMsg: "Required" },
    { title: "Confirm Password", name: "cPassword", type: "password", required: true, errorMsg: "Required" },
    { title: "Profile", name: "selfie", type: "file", required: true, errorMsg: "Required" },
    { title: "Register As", name: "registerAs", fields: ["User", "Staff"], select: true, required: true, errorMsg: "Required" },
    { title: "Address", name: "address", type: "text", required: true, errorMsg: "Required", lastOne: "address" },

]
export const expenseData = [
    { title: "Title", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Description", name: "description", type: "text", required: true, errorMsg: "Required" },
    { title: "Expenses (Rs)", name: "expenses", type: "Number", required: true, errorMsg: "Required" },
    { title: "Type", name: "type", fields: ["Daily", "Weekly", "Monthly"], select: true, required: true, errorMsg: "Required" },

] 
export const sliderData = [
    { title: "Title", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Image", name: "image", type: "file", required: true, errorMsg: "Required" },
]


//update fields
export const updateStaff = [
    { title: "First Name", name: "firstName", type: "text", required: true, errorMsg: "Required" },
    { title: "Last Name", name: "lastName", type: "text", required: true, errorMsg: "Required" },
    { title: "Username", name: "username", type: "text", required: true, errorMsg: "Required" },
    { title: "Email", name: "email", type: "text", required: true, errorMsg: "Required" },
    { title: "Phone #", name: "phone", type: "text", required: true, errorMsg: "Required" },
    { title: "Profile", name: "selfie", type: "file", required: true, errorMsg: "Required", lastOne: "selfie" },


]
export const updateUser = [
    { title: "First Name", name: "firstName", type: "text", required: true, errorMsg: "Required" },
    { title: "Last Name", name: "lastName", type: "text", required: true, errorMsg: "Required" },
    { title: "Username", name: "username", type: "text", required: true, errorMsg: "Required" },
    { title: "Email", name: "email", type: "text", required: true, errorMsg: "Required" },
    { title: "Phone #", name: "phone", type: "text", required: true, errorMsg: "Required" },
    { title: "Profile", name: "selfie", type: "file", required: true, errorMsg: "Required", lastOne: "selfie" },


]
export const updateShop = [
    { title: "Package name", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Description", name: "description", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Duration", name: "duration", type: "text", required: true, errorMsg: "Required" },
    { title: "Bonus Days", name: "bonusDays", type: "text", required: true, errorMsg: "Required" },
    { title: "Price (Rs)", name: "price", type: "text", required: true, errorMsg: "Required" },
    { title: "Discounts (Rs)", name: "discount", type: "text", required: true, errorMsg: "Required", lastOne: "discount" },

]
export const updatePackage = [
    { title: "Package name", name: "title", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Description", name: "description", type: "text", required: true, errorMsg: "Required" },
    { title: "Package Duration", name: "duration", type: "text", required: true, errorMsg: "Required" },
    { title: "Bonus Days", name: "bonusDays", type: "text", required: true, errorMsg: "Required" },
    { title: "Price (Rs)", name: "price", type: "text", required: true, errorMsg: "Required" },
    { title: "Discounts (Rs)", name: "discount", type: "text", required: true, errorMsg: "Required" },
    { title: "Internet Speed", name: "totalMb", fields: ["4Mbps", "6Mbps", "10Mbps"], select: true, required: true, errorMsg: "Required", lastOne: "totalMb" },

]
export const updateTicket = [
    { title: "Status", name: "status", fields: ["Approved", "Pending"], select: true, required: true, errorMsg: "Required", lastOne: "status" },
]
export const updatePayment = [
    { title: "Status", name: "status", fields: ["Approved", "Pending"], values: [true, false], select: true, required: true, errorMsg: "Required", lastOne: "status" },
]
export const updateBills = [
    { title: "Status", name: "status", fields: ["Approved", "Pending"], values: [true, false], select: true, required: true, errorMsg: "Required", lastOne: "status" },
]


