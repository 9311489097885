// import { useState } from 'react'
import PackageModal from '../../components/modalRight/ModalRight'
import { pakagesInputData } from '../../data/inputData'
// import { packageFields, updatePackageFields } from '../../data/inputFields'
import { packageColumns } from '../../data/columns'
import useToggle from '../../hooks/useToggle'

import AllPackages from './AllPackages'
import { updatePackage } from '../../data/inputData'

const AddPakages = () => {
    const { toggleModal, setToggleModal } = useToggle()
    // const [inputData, setInputData] = useState(packageFields)

    return (
        <>

            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>

                <AllPackages
                    title="All packages"
                    modalTitle="Update Package"
                    // modalName="packageModal"
                    url="internet/shop/get"
                    columns={packageColumns}
                    setToggleModal={setToggleModal}
                    id="packageModal"
                    category="packages"
                    // fieldsToUpdate={updatePackageFields}
                    updateUrl="internet/shop/update/"
                    deleteUrl="internet/shop/delete/"
                    method="put"
                    noFormData={true}
                    actionLabel="Create"
                    updateInputArr={updatePackage}
                    filterData={true}
                    filterAs={['package']}
                    filterBy={['type']}
                    actions={['update']}
                    cardData={[
                        { title: "All Packages", background: "blueviolet", color: "#fff" }]
                    }
                />
            </div>

            <PackageModal
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
                title="Add Packages"
                inputsArr={pakagesInputData}
                // inputData={inputData}
                // setInputData={setInputData}
                width="100%"
                actionLabel="Create"
                url="internet/shop/create"
                method="post"
                noFormData={false}
                type='package'
            />

        </>
    )
}

export default AddPakages