import axios from 'axios'
const API_URL = process.env.REACT_APP_API_URL

export const fn_axiosGet = (url) => {
    return axios.get(API_URL + url)

}

export const fn_axiosPost = (url, body) => {
    return axios.post(API_URL + url, body)

}
export const fn_axiosPut = (url, body) => {
    return axios.put(API_URL + url, body)

}
export const fn_axiosDelete = (url) => {
    return axios.delete(API_URL + url)

}
