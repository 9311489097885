

const Input = ({ onSendMessage, setMessageText, value }) => {


    return (
        <div className="input">
            <input
                type="text"
                placeholder="Type something..."
                value={value}
                onChange={(e) => setMessageText(e.target.value)}
            />
            <div className="send">
                <button onClick={onSendMessage}>Send</button>
            </div>
        </div>
    );
};

export default Input;
