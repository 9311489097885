// import { useState } from 'react'
import SideModal from '../../components/modalRight/ModalRight'
import { expenseData } from '../../data/inputData'
import { expenseColumns } from '../../data/columns'
import useToggle from '../../hooks/useToggle'
import AllExpenses from '../pakages/AllPackages'
import useCustomContext from '../../hooks/useContext'
import { useState } from 'react'

const Expenses = () => {
    // const { data } = useCustomContext()
    const [inputData, setInputData] = useState({})
    const { toggleModal, setToggleModal } = useToggle()

    const data = [{
        title: "Buy Shoe", description: "Good", expense: "2000",
        createdAt: "5/4/2023", type: "daily"
    }, {
        title: "Buy Shirt", description: "Good", expense: "5000",
        createdAt: "5/4/2023", type: "weekly"
    }, {
        title: "Buy Jacket", description: "Good", expense: "2000",
        createdAt: "5/4/2023", type: "monthly"
    }, {
        title: "Buy Gel", description: "Good", expense: "2000",
        createdAt: "5/4/2023", type: "monthly"
    },
    {
        title: "Buy Gel", description: "Good", expense: "8000",
        createdAt: "5/4/2023", type: "monthly"
    }]

    const calculteExpense = function (type) {
        return data.filter(item => item.type === type).reduce((acc, item) => {
            return acc + parseInt(item.expense)
        }, 0)
    }
    return (
        <>

            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>

                <AllExpenses
                    title="Daily Expenses"
                    titleSecond="Weekly Expenses"
                    titleThird="Monthly Expenses"
                    setToggleModal={setToggleModal}
                    hasTabs={true}
                    url="internet/expenses/get"
                    columns={expenseColumns}
                    id="expenseModal"
                    category="expense"
                    deleteUrl="internet/expenses/delete/"
                    method="put"
                    noFormData={false}
                    filterData={true}
                    updateInputArr={expenseData}
                    actions={['delete']}
                    filterAs={['daily', "weekly", "monthly"]}
                    filterBy={['type', 'type', "type"]}
                    tabList={["Daily", "Weekly", "Monthly"]}
                    actionLabel="Create"
                    cardData={[{ title: "Daily Expenses", count: "Rs " + calculteExpense("daily"), background: "blueviolet", color: "#fff" },
                    { title: "Weekly Expenses", count: "Rs " + calculteExpense("weekly"), background: "blueviolet", color: "#fff" },
                    { title: "Monthly Expenses", count: "Rs " + calculteExpense("monthly"), background: "blueviolet", color: "#fff" }
                    ]
                    }
                />

            </div>


            <SideModal
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
                title="Add Expenses"
                inputsArr={expenseData}
                inputData={inputData}
                setInputData={setInputData}
                width="100%"
                actionLabel="Create"
                url="internet/expenses/create"
                method="post"
                noFormData={false}
                type='expense'

            />
        </>
    )
}

export default Expenses