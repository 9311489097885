import "./style.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageTab from "./ImageTabs/ImageTab";
import EditIcon from "@mui/icons-material/Edit";
import useFetcher from '../../hooks/useFetcher'
import { useCallback, useState } from "react";
import { fn_axiosPut } from "../../hooks/useAxios";
const TableMui = ({ tableHead, hide, show, ticket, packages }) => {
  const [singleUser, setSingleUser] = useState({})
  const data = useFetcher("internet/user/get")
  const rows = data.filter((item) => item.registerAs === 'user' && item.status === true)
  console.log(rows);
  const [inputData, setInputData] = useState({
    status: ""
  })


  const fn_handleInputData = useCallback((e) => {
    const { name, value } = e.target
    setInputData((prev) => ({ ...prev, [name]: value }))
  }, [inputData])

  async function fn_handleSubmitForm(e, singleUser) {
    e.preventDefault()
    const formData = new FormData()
    for (let key in inputData) { formData.append(key, inputData[key]) }
    await fn_axiosPut("internet/ticket/update/" + singleUser?.userId, formData)
  }

  return (
    <section
      className={hide ? "section" : null}
      style={{
        maxHeight: "75vh",
        overflowY: "auto",
        marginTop: "1rem",
      }}
    >
      {hide && <ImageTab />}
      <TableContainer component={Paper} className="table">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow style={{ background: show && "#F5F5F7" }}>
              {tableHead.map((item) => (
                <TableCell className="tableHeadCell" key={item}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 ?
              rows?.map((row, i) => (
                <TableRow key={i} className="tableRow">
                  <TableCell className="tableCell">
                    <div className="cellWrapper">
                      <img src={row.selfie} alt="" className="image" />
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="cellWrapper">

                      <span>{row.firstName} {row.lastName}</span>
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="cellWrapper">

                      <span>{row.email}</span>
                    </div>
                  </TableCell>
                  <TableCell className="tableCell">
                    <div className="cellWrapper">

                      <span>{row.phone}</span>
                    </div>
                  </TableCell>



                </TableRow>
              )) : <h5>No User exist</h5>}
          </TableBody>
        </Table>
      </TableContainer>

    </section>
  );
};

export default TableMui;
