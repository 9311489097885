import useFetcher from '../../hooks/useFetcher'
import ChatsPage from "../../components/chats";
import './style.css'
const Messages = () => {
    const data = useFetcher("internet/user/get")
    const users = data.filter((item) => item.registerAs === 'user')

    return (

        <ChatsPage data={users} />

    )
}

export default Messages