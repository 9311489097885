

const Navbar = () => {

  return (
    <div className='navbarr'>
      {/* <span className="logo">Lama Chat</span> */}
      <div className="user">
        {/* <img src="" alt="" /> */}
        {/* <span>Good</span> */}
        {/* <button >logout</button> */}
      </div>
    </div>
  )
}

export default Navbar