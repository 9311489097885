import axios from "axios";
import { useEffect, useState } from "react";
import { io } from 'socket.io-client'
import Input from "./Input";
import './style.css'
const Messages = ({ singleUser }) => {
    const BaseUrl = process.env.REACT_APP_API_URL
    const socket = io('https://backend.connect-premier.com/ticket');
    const [data, setData] = useState([]);
    const [messages, setMessages] = useState(data);
    const [messageText, setMessageText] = useState('');
    const userId = 1;
    useEffect(() => {
        // const userId = JSON.parse(localStorage.getItem('mainuserId') || 1);
        const getChat = () => {
            try {

                axios.get(`${BaseUrl}internet/chat/getAll/${singleUser?.id}`).then(res => {

                    setData(res.data.data);
                    setMessages(res.data.data)
                });
            } catch (error) {
                console.log("error");
            }
        }
        singleUser?.id && getChat()


    }, [singleUser?.id])


    useEffect(() => {
        // Connect to the WebSocket server
        socket.connect();
        console.log('WebSocket connected'); // Log the connection status

        // Listen for incoming messages
        socket.on('getMessage', (message) => {
            console.log("new message", message);
            setMessages((prevMessages) => [...prevMessages, message]);
        });

        return () => {

            console.log('WebSocket disconnected'); // Log the disconnection status
        };
    }, []);



    const handleSendMessage = async () => {


        console.log(messageText);



        if (messageText.length !== 0) {

            socket.emit('newMessage', {
                message: messageText,
                // userId,
                sender: 'admin',
            });
            setMessageText('');
        }
    };





    return (
        <>

            <div className="messages">
                {messages.map((msg, i) => (

                    <div className={`message}`} key={i}>

                        <div className="messageContent" id={msg.sender}>
                            <div>
                                <p>{msg.message}</p>
                                <p>{msg.date}</p>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <Input onSendMessage={handleSendMessage} setMessageText={setMessageText} value={messageText} />
        </>
    );
};

export default Messages;
