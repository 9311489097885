import { useState } from 'react';
import Tabs from '../../tabs/Tabs'
import './style.css'
import AddIcon from '@mui/icons-material/Add';
import TuneIcon from '@mui/icons-material/Tune';
const TableHeader = ({ title, titleSecond, titleThird, category, hasTabs, tabList = [], tabNumber, setTabNumber, setToggleModal, actionLabel }) => {
    const [toggleTicket, setToggleTicket] = useState(false)
    return (
        <div id='tHeader'
        // style={{ display: "flex", flexDirection: "row-reverse", alignItems: "center", justifyContent: "space-between", }}
        >
            <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}
                className='tHeader_content'
            >
                {hasTabs &&
                    <div>
                        <Tabs tabList={tabList} tabNumber={tabNumber} setTabNumber={setTabNumber} />
                    </div>
                }
                {category === 'ticket' &&
                    <div style={{ position: "relative", display: "none" }}>

                        <button type='button' style={{ background: "transparent", color: "blueviolet", display: "flex", alignItems: "center", border: "none" }}
                            onClick={() => setToggleTicket((prev) => !prev)}>

                            <TuneIcon fontSize='medium' />

                        </button>
                        <div style={{
                            display: toggleTicket ? "block" : "none",
                            background: "blueviolet", color: "#fff", borderRadius: "0.4rem",
                            width: "150px", position: "absolute", padding: "0.3rem",
                            right: "50%"
                        }}>
                            <h6 style={{ borderBottom: "1px solid #fff", padding: "0.4rem 0 " }}>Daily</h6>
                            <h6 style={{ borderBottom: "1px solid #fff", padding: "0.4rem 0 " }}>Weekly</h6>
                            <h6>Monthly</h6>
                        </div>
                    </div>

                }
                {actionLabel &&
                    <button className='createBtn' type='button' style={{ background: "blueviolet", color: "#fff", display: "flex", alignItems: "center" }} onClick={() => setToggleModal((prev) => !prev)}>{actionLabel}

                        <AddIcon fontSize='small' />

                    </button>

                }

            </div>
            <div
                style={{ marginTop: "1rem", marginRight: "1rem", flex: 1, textAlign: "start" }}
            >

                <h4>{tabNumber === 0 ? title : tabNumber === 1 ? titleSecond : titleThird}</h4>

            </div>
        </div>
    )

}

export default TableHeader