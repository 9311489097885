import "./styles.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import IconButton from "@mui/material/IconButton";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../../context/AppContextProvider";
import toast from "react-hot-toast";

const Login = () => {
  const [data, setData] = useState({ email: "", password: "" })
  const navigator = useNavigate()
  function fn_handleData(e) {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }

  const fn_handleLogin = (e) => {
    e.preventDefault()
    if (data.email === 'admin@gmail.com' && data.password === 'admin') {
      navigator('/home')
      toast.success('Login Successfully')
      localStorage.setItem('userId', true)
    } else {
      toast.error('Login Failed')
    }
  }

  return (
    <>
      <section className="login">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-7">
              <div style={{ margin: "0.4rem 0" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <HomeOutlinedIcon />
                  <span style={{ marginLeft: "0.5rem" }}>Brand Logo</span>
                </div>
                <div>
                  <img alt="banner" src={require("../../../images/banner.png")} width="100%" />
                </div>
              </div>
            </div>
            <div className="col-md-5" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <form>
                <h5 style={{ color: "#636578" }}>Welcome to Internet Services!</h5>
                <p style={{ color: "#A595A2", fontSize: "0.8rem" }}>
                  Please sign-in to your account and start the adventure
                </p>
                <div className="form-floating mb-3">
                  <input type="email" className="form-control" id="floatingInput" name="email" placeholder="Your email" onChange={fn_handleData} />
                  <label for="floatingInput" style={{ fontSize: "0.8rem", color: "#636578" }}>
                    Email address
                  </label>
                </div>
                <div className="form-floating mb-3">
                  <input type="password" className="form-control" id="floatingInput" name="password" placeholder="Your password" onChange={fn_handleData} />
                  <label for="floatingInput" style={{ fontSize: "0.8rem", color: "#636578" }}>
                    Password
                  </label>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    fontSize: "0.8rem",
                    color: "#636578",
                    margin: "0.3rem",
                  }}
                >
                  <span>Remember Me</span>
                  <span>Forgot Password?</span>
                </div>
                {/* <Link to="/home"> */}
                <button onClick={fn_handleLogin} className="btn w-100" style={{ background: "#666CFF", color: "#fff", fontSize: "1rem" }}>
                  LOGIN
                </button>
                {/* </Link> */}
                <p
                  style={{
                    fontSize: "0.8rem",
                    color: "#636578",
                    margin: "0.3rem",
                    textAlign: "center",
                  }}
                >
                  New on our platform? Create an account
                </p>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span style={{ background: "gray", width: "20%", height: "0.1rem", opacity: 0.2 }}></span>
                  <span style={{ margin: "0 0.4rem", color: "gray" }}>or</span>
                  <span style={{ background: "gray", width: "20%", height: "0.1rem", opacity: 0.2 }}></span>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <IconButton size="small">
                    <FacebookIcon style={{ color: "#666CFF" }} />
                  </IconButton>
                  <IconButton size="small">
                    <TwitterIcon style={{ color: "#1DA1F2" }} />
                  </IconButton>
                  <IconButton size="small">
                    <GitHubIcon />
                  </IconButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
