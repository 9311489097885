//simple table columns

export const userColumns = ["Image", "Name", "Phone #", "Email", "Status", "Actions"];
export const shopColumns = ["Image", "Title", "Description", "Price (Rs)", "Discount (Rs)", "Actions"];
export const packageColumns = ["Title", "Price (Rs)", "Description", "Discounts (Rs)", "Bonus Days", "Duration", "Actions"];
export const ticketColumns = ["USERNAME", "TITLE", "LOCATION", "LATITUDE", "LONGITUDE", "ASSIGN TO", "DESCRIPTION", "STATUS", "Action"];
export const paymentColumns = ["USER ID", "METHOD", "AMOUNT (Rs)", "STATUS", "ACTIONS"];
export const expenseColumns = ["TITLE", "DESCRIPTION", "EXPENSES (Rs)", "DATE", "ACTIONS"];
export const billsColumns = (noAction) => noAction ? ["USERNAME", "PACKAGE NAME", "DESCRIPTION", "DURATION (Days)", "TOTAL(Rs)", "STATUS"] :
    ["USER ID", "TOTAL BILL (Rs)", "BUY DATE", "STATUS", "ACTIONS"]
export const ordersColumns = (noAction) => noAction ? ["USER ID", "TOTAL BILL (Rs)", "BUY DATE", "LOCATION", "STATUS"] :
    ["USER ID", "TOTAL BILL (Rs)", "BUY DATE", "LOCATION", "STATUS", "ACTIONS"]
export const sliderColumns = () => ["IMAGE", "TITLE", "ACTIONS"]


//datatable columns
export const staffColumns = [
    {
        field: "name",
        headerName: "NAME",
        width: 280,

        renderCell: (params) => {
            return (
                <div className="cellWithImg">
                    {/* <img className="cellImg" src={params.row.selfie} alt="avatar" /> */}
                    <p>{params.row.firstName} {params.row.lastName}</p>
                    {/* <p>{params.row.username}</p> */}
                </div>
            );
        },
    },
    {
        field: "phone",
        headerName: "PHONE",
        width: 200,
        renderCell: (params) => {
            return <div className="cellWithStatus">
                {params.row.phone}
            </div >;
        },
    },

    {
        field: "email",
        headerName: "EMAIL",
        width: 250,
        renderCell: (params) => {
            return <div className="cellWithStatus">
                {params.row.email}
            </div>;
        },
    },

];


//bills values
export const orders_val = { v1: "userId", v2: "totalBill", v3: "buyDate", v4: "location", v5: "status" }
export const slider_val = { v1: "image", v2: "title" }

