import { useState } from 'react';
import './style.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Cells from "./Cells";
import { fn_axiosPut } from '../../hooks/useAxios';
import useSearch from '../../hooks/useSearch';
import TableHeader from '../Table/header/TableHeader';
import useFormFn from '../modalRight/functions';
import Modal from '../modal/Modal';
import useFetcher from '../../hooks/useFetcher';
import Card from '../newCard/Card';
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";



const SimpleTable = ({
    title, titleSecond, titleThird, tabNumber, setTabNumber, columns, valuesData, setToggleModal,
    modalTitle, modalData = [], updateInputArr, hide, show, modalID, type,
    deleteUrl, url, updateUrl, method = "put", noFormData, selectName, actions, actionLabel, cardData
}) => {
    const [selectedRow, setSelectedRow] = useState({})
    const [inputData, setInputData] = useState({})
    const { isLoading, fn_handleInputData, fn_handleSubmitForm } = useFormFn(inputData, setInputData, updateUrl + selectedRow?.id, method, noFormData)
    const rows = useFetcher(url)
    // .concat([{
    //     id: 1, title: "New bills", description: "Good bill", status: "Approved",
    // },
    // { id: 2, title: "Recent bills", description: "Bad bill", status: "Pending" },
    // { id: 3, title: "Recent bills", description: "Bad bill", status: "Recent" },

    // ])
    const { fn_deleteData, splicedArr } = useSearch(rows, '')
    async function fn_handleUpdate(row) {
        try {
            await fn_axiosPut(updateUrl + row.id, { status: true })
        }
        catch (error) { console.log("error") }
    }



    //table component
    return (
        <>
            {cardData?.[0] && <Card title={cardData[0]?.title} count={rows.length} icon={ArrowOutwardIcon} background={cardData[0]?.background}
                color={cardData[0]?.color} />
            }
            <section
                className={hide ? "section" : null}
                style={{ maxHeight: "75vh", overflowY: "auto", marginTop: "1rem" }} >


                <TableHeader
                    title={title}
                    titleSecond={titleSecond}
                    titleThird={titleThird}
                    tabNumber={tabNumber}
                    setTabNumber={setTabNumber}
                    setToggleModal={setToggleModal}
                    actionLabel={actionLabel}
                // hasTabs
                // actionLabel={actionLabel}
                />
                <TableContainer component={Paper} className="table">
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow style={{ background: show && "#F5F5F7" }}>
                                {columns?.map((item, i) => (
                                    <TableCell className="tableHeadCell" key={i}>
                                        {item}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>


                            {(splicedArr ?? rows).map((item) => (
                                <Cells
                                    item={item}
                                    valuesData={valuesData}
                                    actions={actions}
                                    setSelectedRow={setSelectedRow}
                                    onDelete={fn_deleteData}
                                    deleteUrl={deleteUrl}
                                    modalID={modalID}
                                    fn_handleUpdate={fn_handleUpdate}
                                />
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>

                {/* modal component */}
                <Modal
                    id={modalID}
                    title={modalTitle}
                    data={selectedRow}
                    updateInputArr={updateInputArr}
                    modalData={modalData}
                    selectName={selectName}
                    type={type}
                    isLoading={isLoading}
                    onChange={fn_handleInputData}
                    onSubmit={fn_handleSubmitForm}

                />
            </section>
        </>
    );
};

export default SimpleTable;
