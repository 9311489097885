import SidebarLayout from "./sidebarLayout/SidebarLayout";

const Sidebar = ({ toggleMenu, setToggleMenu }) => {
  return (
    <>
      {/* for small screen */}

      {toggleMenu && (
        <>
          <div className="overlay_sidebar"></div>
          <section className="sidebarSmallScreen_wrapper">
            <SidebarLayout toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
          </section>
        </>
      )}
      {/* for large screen */}
      <section className="sidebar_wrapper d-none d-xl-block">
        <SidebarLayout />
      </section>
    </>
  );
};

export default Sidebar;
