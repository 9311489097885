import { useState } from "react";
import "./style.css";

const StaffInputs = ({ showModal, title, type, left, placeholder, name, onChange, hasValue, errorMsg, required, lastOne, pattern, inputData }) => {
  const [focused, setFocused] = useState(false);
  const handleFocus = () => {
    setFocused(true);
  };
  return (
    <div className="staffForm_input" style={{
      left: (left === 0 || showModal) && 0,
      opacity: showModal && 1,
    }}>
      {type === 'file' && <label>{title} :</label>}

      <input type={type} placeholder={placeholder} name={name}
        onChange={onChange}
        defaultValue={hasValue || null}
        required={required}
        pattern={name === "cPassword" ? inputData?.password : pattern}
        onBlur={handleFocus}
        onFocus={() => name === lastOne && setFocused(true)}
        focused={focused.toString()}
      />

      <span>{errorMsg}</span>
    </div>
  );
};
export const SelectInput = ({ name, onChange, field, left, title, width, showModal, errorMsg, required, values, lastOne }) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  }
  return (
    <div className="select_Box" style={{
      width: width,
      left: (left === 0 || showModal) && 0,
      opacity: showModal && 1
    }}>
      <label>{title} :</label>
      <select name={name} onChange={onChange} required={required} onBlur={handleFocus}
        onFocus={() => name === lastOne && setFocused(true)}
        focused={focused.toString()}>
        <option selected disabled value="">---Select----</option>
        {
          field.map((item, i) => <option key={item + i} value={values?.[i]}>{item}</option>)
        }
      </select>
      <span>{errorMsg}</span>
    </div >
  )
}
export const SelectWithAPI = ({ name, onChange, field, left, title, width, showModal, showBy, valueBy, errorMsg, required, lastOne }) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
  }
  return (
    <div className="select_Box" style={{
      width: width,
      left: (left === 0 || showModal) && 0,
      opacity: showModal && 1
    }}>
      <label>{title} :</label>
      <select name={name} onChange={onChange} required={required} onBlur={handleFocus}
        onFocus={() => name === lastOne && setFocused(true)}
        focused={focused.toString()}>
        <option selected disabled value="">---Select----</option>
        {
          field.map((item, i) => <option key={item + i} value={item[valueBy] || true}>{item[showBy?.[0]]} {item[showBy?.[1]]}</option>)
        }
      </select>
      <span>{errorMsg}</span>
    </div >
  )
}
export default StaffInputs;
