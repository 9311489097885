import { Button } from "@mui/material"
import './style.css'
import StaffInputs from "../../components/inputs/StaffInputs"
import { useState } from "react"
import axios from "axios"
import toast from "react-hot-toast"
const Notification = () => {
  const [data, setData] = useState({})
  const API_URL = process.env.REACT_APP_API_URL
  function fn_handleData(e) {
    const { name, value } = e.target
    setData({ ...data, [name]: value })
  }
  async function fn_handleSubmit(e) {
    e.preventDefault()
    try {
      await axios.post(API_URL + 'internet/notification/create', data)
      toast.success('Created...')
    } catch (error) {
      toast.error('Error...')
    }
  }

  return (
    <form
      onSubmit={fn_handleSubmit}
      id="notification"
      style={{
        display: "flex", flexDirection: "column",
        height: "80vh", width: "50%", margin: "0 auto"
      }}
    >
      <h4 style={{ marginTop: "1rem", textAlign: "center" }}>Notification</h4>
      <div>
        {/* 
        <StaffInputs
          left={0}
          title="Title"
          name="title"
          type="text"
          placeholder="Title"
          onChange={}
        /> */}
        <input type="text" name="title" placeholder="Title" style={{ width: "100%", height: "50px", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "0.4rem", marginBottom: "1rem" }} onChange={fn_handleData} />
        <input type="textarea" name="description" placeholder="Description" style={{ width: "100%", height: "100px", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "0.4rem" }} onChange={fn_handleData} />
        <Button type='submit' variant="contained" style={{ width: "100%", marginTop: "1rem" }} >Create</Button>
      </div>
    </form>

  )
}

export default Notification