import ShopModal from '../../components/modalRight/ModalRight'
import { shopInputData } from '../../data/inputData'
import { shopFields } from '../../data/inputFields'
import { shopColumns } from '../../data/columns'
import useToggle from '../../hooks/useToggle'

import AllShops from '../pakages/AllPackages'
import { updateShop } from '../../data/inputData'


const Shop = () => {
    const { toggleModal, setToggleModal } = useToggle()




    return (
        <>
            <div style={{ textAlign: "end", paddingRight: "0.5rem", }}>
                <AllShops
                    title="All Products"
                    url="internet/shop/get"
                    columns={shopColumns}
                    setToggleModal={setToggleModal}
                    id="shopModal"
                    category="shop"
                    // fieldsToUpdate={updateShopFields}
                    updateUrl="internet/shop/update/"
                    deleteUrl="internet/shop/delete/"
                    method="put"
                    actionLabel="Products"
                    noFormData={false}
                    modalTitle="Update Shop"
                    updateInputArr={updateShop}
                    filterData={true}
                    filterAs={['shop']}
                    filterBy={['type']}
                    actions={['update', 'delete']}
                    cardData={[
                        { title: "All Products", background: "blueviolet", color: "#fff" }]}

                />
            </div>

            <ShopModal
                toggleModal={toggleModal}
                setToggleModal={setToggleModal}
                title="Add Products"
                inputsArr={shopInputData}
                // inputData={inputData}
                // setInputData={setInputData}
                width="100%"
                actionLabel="Create"
                url="internet/shop/create"
                method="post"
                noFormData={false}
                picField={["image"]}
                type='shop'
            />

        </>
    )
}

export default Shop