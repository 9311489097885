import { Link } from "react-router-dom";
import "./styles.css";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
const SideButtons = ({ hasIcon, linkName, icon: Icon, title, name, setName }) => {

  return (
    <section className="sideButton_wrapper" >
      <Link to={"/" + linkName} onClick={() => setName(title)} >
        <div className="sideButton_content" style={{ background: name === title && "blueviolet" }}>
          <div >
            <Icon fontSize="medium" style={{
              marginRight: "1rem", color: name === title ? "#fff" : "#636578", opacity: 0.8,
            }} />
            <span style={{ color: name === title ? "#fff" : "#636578", fontSize: "0.9rem", letterSpacing: "0.2px" }}>{title}</span>
          </div>

          {hasIcon && (
            <div>
              <ArrowForwardIosOutlinedIcon style={{ fontSize: "0.7rem", color: name === title ? "#fff" : "#636578" }} />
            </div>
          )}
        </div>
      </Link>
    </section>
  );
};

export default SideButtons;
