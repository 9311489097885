import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";

import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";

import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import "./staffCard.css";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard({ name, img, email, status, role, contact }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  //   sx={{ maxWidth: 345, mt: 1 }}
  return (
    <Card id="staffCard_container">
      <div>
        <CardMedia
          component="img"
          height="194"
          width="50"
          image={img}
          alt="profileImg"

          style={{ objectFit: "contain", borderRadius: "2rem", }}
        />
        <div className="staffName_box">
          <h5>{name}</h5>
          <h6>{role}</h6>
        </div>
        {/* tasks */}
        {/* <div className="staff_tasks">
          <div>
            <span>
              <DoneIcon />
            </span>
            <div>
              <h6>1.23k</h6>
              <p>Tasks done</p>
            </div>
          </div>
          <div>
            <span>
              <DoneIcon />
            </span>
            <div>
              <h6>1.23k</h6>
              <p>Tasks done</p>
            </div>
          </div>
        </div> */}
      </div>
      <CardActions disableSpacing>
        {/* <IconButton aria-label="editStaff_button">
          <EditIcon />
        </IconButton> */}

        <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit className="collapse_menu">
        <CardContent className="staff_cardContent">
          <Typography paragraph>
            <span>Username:</span> <span>{name}</span>
          </Typography>
          <Typography paragraph>
            <span>Billing Email:</span> <span>{email}</span>
          </Typography>
          <Typography paragraph>
            <span>Status:</span> <span className="staff_status">{status}</span>
          </Typography>
          <Typography paragraph>
            <span>Role:</span> <span>{role}</span>
          </Typography>
          <Typography paragraph>
            <span>Contact:</span> <span>{contact}</span>
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
