
import { CircularProgress } from '@mui/material';
import StaffInputs, { SelectInput } from '../inputs/StaffInputs'
import './form.css'
import CloseIcon from "@mui/icons-material/Close";
const Form = ({ inputsArr, onSubmit, width, formWidth, alignItem, title, setToggleModal,
    actionLabel, onChange, showModal, isLoading,
    defaultValue, inputData }) => {

    return (
        <form
            className='form_component'
            style={{ width: formWidth ? formWidth : "100%", textAlign: alignItem ? alignItem : "end" }}
            onSubmit={onSubmit}>
            <div>
                <h5>{title}</h5>
                <span style={{ cursor: "pointer" }} onClick={() => setToggleModal((prev) => !prev)}><CloseIcon /></span>
            </div>
            <div className='animation_inputs'>

                {inputsArr.map((item, i) =>
                    <div key={item.name}>
                        {
                            item.select ?
                                <SelectInput showModal={showModal} width={width} title={item.title} name={item.name} field={item.fields}
                                    onChange={onChange} defaultValue={defaultValue?.[i]}
                                    required={item?.required} errorMsg={item?.errorMsg}
                                    lastOne={item?.lastOne}

                                /> :
                                <StaffInputs showModal={showModal} type={item.type}
                                    title={item.title} placeholder={item.title} name={item.name}
                                    onChange={onChange} defaultValue={defaultValue?.[i]}
                                    required={item?.required}
                                    errorMsg={item?.errorMsg}
                                    lastOne={item?.lastOne}
                                    pattern={item?.pattern}
                                    inputData={inputData}
                                />
                        }
                    </div>
                )}
            </div>


            <button type="submit" className="formBtn"
                style={{ width: showModal ? "100%" : "30%", opacity: showModal && 1, display: "flex", alignItems: "center", justifyContent: "center", fontWeight: 500 }}
            >
                {actionLabel} {isLoading && <CircularProgress size={20} style={{ color: "#fff", marginInline: "1rem" }} />}
            </button>
        </form>
    )
}

export default Form