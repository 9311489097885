import './style.css';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Modal from "../../modal/Modal";
// import {  useState } from "react";
import useFormFn from '../../modalRight/functions.js'
import useSearch from '../../../hooks/useSearch';
import { Button } from '@mui/material';
import { fn_axiosPut } from '../../../hooks/useAxios';
import { useContext } from 'react';
import { AppContext } from '../../../context/AppContextProvider';
import { useToggleTabs } from '../../../hooks/useToggle';
import toast from 'react-hot-toast';
// import { PackagesCell } from '../cells/Cells';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
const TableMui = ({
    modalTitle, modalData = [],
    // modalName,
    rows, updateInputArr, tableHead, hide, show, id, type,
    inputData, setInputData,
    selectedRow, setSelectedRow,
    deleteUrl, url, method = "put", noFormData, pendingUsers,
    selectName, actions, navigateUrl
}) => {
    // const [rowsData, setRowsData] = useState(null)
    // const [forcedRender, setForcedRender] = useState(false)

    const { isLoading, fn_handleInputData, fn_handleSubmitForm } = useFormFn(inputData, setInputData, url + selectedRow?.id, method, noFormData)
    const { fn_deleteData, splicedArr } = useSearch(rows, '')
    const navigate = useNavigate()

    async function fn_handleUpdate(row) {
        try {
            if (id === "userModal") {

                await fn_axiosPut(url, {
                    status: true,
                    phone: row?.phone
                })
                toast.success("Approved...")
            } else {
                await fn_axiosPut(url + row?.id, {
                    status: true,
                })
                toast.success("Approved...")
            }
        }
        catch (error) {
            console.log("error");
            toast.error("Server error...")
        }
    }
    return (
        <section
            className={hide ? "section" : null}
            style={{
                maxHeight: "75vh",
                overflowY: "auto",
                marginTop: "1rem",
            }}
        >
            <TableContainer component={Paper} className="table">
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: show && "#F5F5F7" }}>
                            {tableHead?.map((item) => (
                                <TableCell className="tableHeadCell" key={item}>
                                    {item}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {(splicedArr ?? rows).map((row, i) => (
                            <TableRow key={i} className="tableRow">
                                <TableCell className="tableCell">
                                    <div className="cellWrapper">
                                        {/* <PackagesCell row={row} /> */}
                                        {type === "packages" && row?.title}
                                        {type === "ticket" && row?.users?.firstName + " " + row.users?.lastName}
                                        {type === "shop" &&
                                            <img src={row.image} alt="" className="image" />
                                        }
                                        {type === "user" &&
                                            <img src={row.selfie} alt="" className="image" />
                                        }
                                        {type === "payment" && row?.userId}
                                        {type === "bill" && (row?.users?.firstName + ' ' + row?.users?.lastName)}
                                        {type === "expense" && (row?.title)}
                                    </div>
                                </TableCell>

                                <TableCell className="tableCell">
                                    {type === "packages" && row?.price}
                                    {type === "ticket" && row?.title}
                                    {type === "shop" && row?.title}
                                    {type === "user" && row?.firstName + " " + row?.lastName}
                                    {type === "payment" && row?.method}
                                    {type === "bill" && row?.shopPackage?.title}
                                    {type === "expense" && (row?.description)}

                                </TableCell>

                                <TableCell className="tableCell">
                                    {type === "packages" && row?.description}
                                    {type === "ticket" && row?.location}
                                    {type === "shop" && row?.description}
                                    {type === "user" && row?.phone}
                                    {type === "payment" && row?.amount}
                                    {type === "bill" && row?.shopPackage?.description}
                                    {type === "expense" && (row?.expense)}

                                </TableCell>

                                {type !== "payment" && <TableCell className="tableCell">
                                    {type === "packages" && row?.discount}
                                    {type === "ticket" && row?.lat}
                                    {type === "shop" && row?.price}
                                    {type === "user" && row?.email}
                                    {type === "bill" && row?.packageDuration}
                                    {type === "expense" && (row?.createdAt)}
                                </TableCell>}

                                {(type !== "shop" && type !== "expense") && <TableCell className="tableCell">
                                    {type === "packages" && row?.bonusDays}
                                    {type === "ticket" && row?.long}
                                    {(type === "user" || type === 'payment') && <h6 className={`${row.status ? "approved" : "pending"}`}>{row?.status ? "Approved" : "Pending"}</h6>}
                                    {type === "bill" && row?.amount}
                                </TableCell>
                                }
                                {(type === "ticket") && <TableCell className="tableCell">

                                    {type === "ticket" && row?.staff?.firstName + " " + row?.staff?.lastName}

                                </TableCell>
                                }

                                {
                                    (type !== "user" && type !== "payment" && type !== "bill" && type !== "expense") &&
                                    <TableCell className="tableCell">
                                        {type === "packages" && row?.duration}
                                        {type === "ticket" && row?.description}
                                        {type === "shop" && row?.discount}
                                    </TableCell>
                                }
                                {
                                    (type !== "user" && type !== "packages" && type !== "shop" && type !== "payment" && type !== "expense") &&
                                    <TableCell className="tableCell">

                                        {type === 'ticket' && <h6 className={`${row.status !== 'pending' ? "approved" : "pending"}`}>{row?.status !== 'pending' ? "Resolved" : "Pending"}</h6>}
                                        {type === 'bill' && <h6 className={`${row.packageStatus ? "approved" : "pending"}`}>{row?.packageStatus ? "Paid" : "Pending"}</h6>}

                                    </TableCell>
                                }
                                {(actions?.length > 0 || pendingUsers) && <TableCell className="tableCell">
                                    {pendingUsers ? <Button variant="contained" size='small' color="primary" onClick={() => fn_handleUpdate(row)}>Approve</Button> :
                                        <>
                                            {actions?.includes("update") && <span style={{ cursor: "pointer" }}
                                                // onClick={() => setInputData(row)}>
                                                onClick={() => setSelectedRow(row)}>
                                                <EditIcon color="primary" fontSize="small" data-bs-toggle="modal"
                                                    data-bs-target={"#" + id} />
                                            </span>}
                                            {actions?.includes("view") && <span style={{ cursor: "pointer" }}
                                                onClick={() => navigate(navigateUrl + row?.id)}><RemoveRedEyeIcon color="secondary" fontSize="small" /></span>}
                                            {actions?.includes("delete") && <span style={{ cursor: "pointer" }} onClick={() => fn_deleteData(row.id, deleteUrl + row.id)}>  <DeleteIcon color="secondary" fontSize="small" /></span>}
                                        </>
                                    }

                                </TableCell>}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Modal
                id={id}
                title={modalTitle}
                data={selectedRow}
                updateInputArr={updateInputArr}
                modalData={modalData}
                selectName={selectName}
                type={type}
                isLoading={isLoading}
                onChange={fn_handleInputData}
                // onSubmit={(e) => fn_handleSubmitForm(e, inputData, selectData)}
                onSubmit={(e) => fn_handleSubmitForm(e, inputData)}

            />
        </section>
    );
};

export default TableMui;
