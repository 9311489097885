// import React, { useContext } from "react";
// import Cam from "../img/cam.png";
// import Add from "../img/add.png";
// import More from "../img/more.png";
import Message from "./Message";


const Chat = ({ singleUser }) => {




    return (
        <div className="chat">
            <div className="chatInfo">
                <span>{singleUser?.firstName + " " + singleUser?.lastName}</span>
            </div>
            <Message singleUser={singleUser} />

        </div>
    );
};

export default Chat;
