import Slider from "./swiper/Swiper";
import CardRow from "./cardRow/CardRow";
import CardSecondRow from "./rowCard/CardSecondRow";
import "./styles.css";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Timeline from "../activityTimeline/Timeline";
import JointCard from "./jointCard/JointCard";
import Table from "../tablce/Table";
// import TableSecond from "../table/TableSecond";
import { rowOne, rowTwo, tableHeadOne, tableHeadTwo } from "../../data/rows";
// import BigCard from "./bigCard/BigCard";
import MediumCard from "./mediumCard/MediumCard";
import GraphCard from "./graphCard/GraphCard";
import DenseGraphCard from "../barGraphLarge/DenseGraphCard";
import Swiper from "../slider/Slider";
import { cardData } from "../../data/products";
import Chart from '../Charts/BasicChart'
const Card = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <CardRow icon={PersonOutlineIcon} />


          <Chart />

          <CardSecondRow data={cardData} />
          {/* <div className="col-md-12 col-lg-6 mt-3">
            <Timeline />
          </div> */}
          {/* <div className="col-12 col-lg-12 mt-3"> */}
          <Table rows={rowTwo} hide tableHead={tableHeadTwo} />
          {/* </div> */}

          {/* <div className="col-md-12 col-lg-4 mt-3">
            <JointCard />
          </div> */}
          {/* <div className="col-md-8 col-lg-7 mt-3">
            <Swiper
              data={cardData}
              children={MediumCard} />
          </div> */}
          {/* <div className="col-md-6 col-lg-5 mt-3">
            <GraphCard title="Live Visitors" subTitle="Total 890 Visitors Are Live" profitLoss="+25%" hideIcon />
          </div> */}
          {/* <div className="col-12 col-lg-12 mt-3">
            <Table rows={rowOne} show tableHead={tableHeadOne} />
          </div> */}
          {/* <div className="col-12 col-lg-4 mt-3">
            <DenseGraphCard title="Visits by Day" subTitle="Total 248.5k Visits" />
          </div> */}

        </div>
      </div>
    </>
  );
};

export default Card;
