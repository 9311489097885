import React from "react";
// import Header from "../../components/Header/Header";
// import Sidebar from "../../components/Sidebar/Sidebar";
import Rightbar from "../../components/rightbar/Rightbar";

const HomePage = () => {
  return (
    <>
      <section style={{ height: "200vh" }}>
        {/* <Header />
        <Sidebar /> */}
        <Rightbar />
      </section>
    </>
  );
};

export default HomePage;
