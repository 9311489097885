import { IconButton } from "@mui/material";
import "./header.css";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import NotificationImportantOutlinedIcon from "@mui/icons-material/NotificationImportantOutlined";
// import Notification from "./notification/Notification";
const Header = ({ toggleMenu, setToggleMenu }) => {
  return (
    <div className="header_wrapper">
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton
          style={{ marginBottom: "0.5rem" }}
          className="d-block d-xl-none"
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <MenuOutlinedIcon />
        </IconButton>

        <IconButton style={{ marginRight: "0.4rem" }}>
          <SearchIcon style={{ fontSize: "1.5rem" }} />
        </IconButton>
        <span>Search here</span>
      </div>
      <div>
        {/* <IconButton>
          <DarkModeOutlinedIcon />
        </IconButton> */}

        {/* noticication */}

        {/* <Notification icon={NotificationImportantOutlinedIcon} /> */}

        <IconButton size="small">
          <Avatar
            alt="Remy Sharp"
            src={require("../../images/man.png")}
            style={{
              width: 30,
              height: 30,
            }}
          />
        </IconButton>
      </div>
    </div>
  );
};

export default Header;
