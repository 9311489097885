import { useEffect, useState } from 'react'
import axios from 'axios'
import useSearch from './useSearch'
import useCustomContext from './useContext'

// import useCustomContext from './useContext'
const useFetcher = (url) => {
    // const { state, setState } = useCustomContext()
    const { setData } = useCustomContext()
    const [state, setState] = useState([])
    const { splicedArr } = useSearch()
    const API_URL = process.env.REACT_APP_API_URL
    const fetcher = async () => {

        try {
            // if(splicedArr.length>0){
            //     splicedArr.find((item)=>item.id===)
            // }
            const { data: { data } } = await axios.get(API_URL + url)
            console.log(data);
            setState(data)
            setData(data)
        } catch (error) {
            console.log("error")
        }
    }
    useEffect(() => {
        fetcher()
    }, [])
    return (
        state
    )
}

export default useFetcher