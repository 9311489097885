import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import "./style.css";
const Layout = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="root">
      <Header toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      <Sidebar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
      <Outlet />
    </div>
  );
};

export default Layout;
