import React, { useContext, useState } from "react";
import StaffInputs, { SelectInput, SelectWithAPI } from "../../components/inputs/StaffInputs";
import { AppContext } from "../../context/AppContextProvider";
import useFormFn from "../modalRight/functions";
import { CircularProgress } from "@mui/material";

const Modal = ({ id, title, onChange, onSubmit, data, updateInputArr, modalData, type, isLoading

}) => {


    return (
        <>
            {/* Modal */}
            <div className="modal fade" id={id} tabIndex={-1} aria-labelledby={id} aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id={id}>
                                {title}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        </div>
                        <div className="modal-body">
                            <form onSubmit={onSubmit}>
                                <div className="row">

                                    {type === 'ticket' && <SelectWithAPI
                                        name="assignId"
                                        title="Assign Tickets"
                                        onChange={onChange}
                                        field={modalData}
                                        left={0}
                                        showBy={["firstName", "lastName"]}
                                        valueBy="id"

                                    />}
                                    {/* {type === 'payment' && <SelectWithAPI
                                        name="status"
                                        title="Approve Payment"
                                        onChange={onChange}
                                        field={modalData}
                                        left={0}
                                        showBy={["method", ""]}
                                    />} */}

                                    <div className="col-12">

                                        {updateInputArr?.map((field) => (
                                            <>
                                                {(field?.select) ?

                                                    <SelectInput
                                                        name={field.name}
                                                        title="Update Status"
                                                        onChange={onChange}
                                                        hasValue={data?.[field.name]}
                                                        field={field?.fields}
                                                        values={field.values}
                                                        left={0}
                                                        required={field?.required}
                                                        errorMsg={field?.errorMsg}
                                                        lastOne={field?.lastOne}
                                                    /> :
                                                    <StaffInputs
                                                        type={field.type}
                                                        title={field.title}
                                                        placeholder={field.title}
                                                        name={field.name}
                                                        left={0}
                                                        onChange={onChange}
                                                        hasValue={data?.[field.name]}
                                                        required={field?.required}
                                                        errorMsg={field?.errorMsg}
                                                        lastOne={field?.lastOne}
                                                        pattern={field?.pattern}
                                                    />
                                                }

                                            </>
                                        ))}
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="submit" className="btn btn-primary" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                        Update {isLoading && <CircularProgress size={20} style={{ color: "#fff", marginLeft: "1rem" }} />}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
};

export default Modal;
