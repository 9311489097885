import { Button } from "@mui/material";
import "./style.css";
import { useState } from "react";
import useFetcher from "../../../hooks/useFetcher";

import useSearch from "../../../hooks/useSearch";
import useToggle, { useToggleTabs } from '../../../hooks/useToggle'
import { staffFields, updateStaffFields } from '../../../data/inputFields'

import { staffData, updateStaff } from "../../../data/inputData";
import { staffColumns } from "../../../data/columns";
import SideModal from '../../modalRight/ModalRight'
import Tabs from "../../tabs/Tabs";
import Datatable from "../datatable/StaffDataTable";
import TableHeader from "../../Table/header/TableHeader";

const AddStaff = () => {
  const { toggleModal, setToggleModal } = useToggle()
  const [inputData, setInputData] = useState(staffFields)
  const allStaffArr = useFetcher("internet/user/get")
  const { searchResult, fn_searchData, fn_deleteData } = useSearch(allStaffArr, "firstName")
  const { tabNumber, setTabNumber } = useToggleTabs()


  return (
    <>
      <div className="container-fluid mt-2">
        <div className="row">
          {/* <div className="col-12">
            <Tabs tabList={["Staff", "History"]} tabNumber={tabNumber} setTabNumber={setTabNumber} />
          </div>
          <div className="col-12">
            <div className="addStaff_header">
              {/* <input type="text" placeholder="Search User" onChange={fn_searchData} /> */}

          {/* <Button
            color="primary"
            size="medium"
            variant="contained"
            sx={{ boxShadow: "none" }}
            onClick={() => setToggleModal((prev) => !prev)}
          >
            ADD STAFF
          </Button>
        </div> */}

          {/* </div> */}

          <div className="col-12">
            {tabNumber === 0 &&
              <Datatable
                title="All Staff"
                titleSecond="History"
                tableColumns={staffColumns}
                searchResult={searchResult}
                navigateUrl="/staff"
                hasActions={true}
                actionType={["viewAction", "deleteAction", "updateAction"]}
                modalTitle="Update Staff"
                id="staffModal"
                modalName="staffModal"
                getUrl="internet/user/get"
                url="internet/user/update/"
                deleteUrl="internet/user/delete/"
                method='put'
                noFormData={false}
                setToggleModal={setToggleModal}
                tabNumber={tabNumber}
                setTabNumber={setTabNumber}
                updateFieldsArr={updateStaff}
                fieldsToUpdate={updateStaffFields}
                filterData={true}
                filterAs='staff'
                // tabList={["All Staff", "History"]}
                cardData={[
                  { title: "Total Staff", background: "blueviolet", color: "#fff" }]}

              />
            }
          </div>
          {/* <div className="col-12">
            {tabNumber === 1 &&
              <TableHeader
                actionLabel="create staff"
                hasTabs={true}
                tabNumber={tabNumber}
                setTabNumber={setTabNumber}
                setToggleModal={setToggleModal}
                tabList={["All Staff", "History"]}
                title="History"
                titleSecond="Second Tasks"
              // titleThird={titleThird}
              />
            }
          </div> */}
        </div >
      </div >



      <SideModal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        title="Add Staff"
        inputsArr={staffData}
        inputData={inputData}
        setInputData={setInputData}
        width="100%"
        actionLabel="Create"
        url="internet/user/create"
        method="post"
        noFormData={false}

      />
    </>
  );
};

export default AddStaff;
