import { Button } from '@mui/material'

const Detail = ({ singleUser, verifiedID, onClick }) => {
    return (

        <div className='col-8 mx-auto mt-2 shadow'>
            <div className='row'>
                <div className='col-6'>
                    <img style={{ maxHeight: "400px" }} src={singleUser?.selfie || require('../../images/human.png')} alt='' className='img-fluid' />
                </div>
                <div className='col-6'>
                    <h5>Users Detail</h5>
                    <hr style={{ opacity: 0.1 }} />
                    <div className='col-12'>
                        <h6>Personal Information</h6>
                        <hr style={{ opacity: 0.1 }} />
                    </div>

                    <div className='col-12'>
                        <h6>Name: <span style={{ color: "gray" }}>{singleUser?.firstName} {singleUser?.lastName}</span></h6>
                    </div>
                    <div className='col-12'>
                        <h6>Email: <span style={{ color: "gray" }}>{singleUser?.email}</span></h6>
                    </div>
                    <div className='col-12'>
                        <h6>Phone: <span style={{ color: "gray" }}>{singleUser?.phone}</span></h6>
                    </div>
                    <div className='col-12'>
                        <h6>Wallet: <span style={{ color: "gray" }}>{singleUser?.wallet} Rs</span></h6>
                    </div>
                    <div className='col-12'>
                        <h6>Created Date: <span style={{ color: "gray" }}>{new Date(singleUser?.createdAt)?.toLocaleDateString()}</span></h6>
                    </div>
                    {(verifiedID.includes(singleUser?.id) || singleUser?.status) ?
                        <Button variant='contained' color='secondary' size="small" style={{ pointerEvents: "none" }}>Verified</Button> :
                        <Button variant='contained' color='primary' size="small" onClick={() => onClick(singleUser?.phone, singleUser?.id)}>Verify</Button>
                    }

                </div>
            </div>




        </div>

    )
}

export default Detail