export const products = [
  { count: 3, name: "Mobile" },
  { count: 5, name: "Shirts" },
  { count: 3, name: "Watch" },
  { count: 23, name: "Computer" },
];

export const cardData = [
  {
    title: "Weekly Sales",
    subTitle: "Total $42.5k Earnings",
    profitLoss: "+18%",
    headerText: "Mobiles And Computers",
    products: [
      { count: 3, name: "Mobile" },
      { count: 5, name: "Shirts" },
      { count: 10, name: "Watch" },
      { count: 23, name: "Computer" },
    ],
  },
  {
    title: "Monthly Sales",
    subTitle: "Total $432.5k Earnings",
    profitLoss: "+38%",
    headerText: "Sport Items",
    img: "logo.png",
    products: [
      { count: 3, name: "Laptop" },
      { count: 5, name: "Ring" },
      { count: 10, name: "Shirts" },
      { count: 23, name: "Shoes" },
    ],
  },
];
// title="Marketing & Sales"
// subTitle="Total 245.8k Sales"
// profitLoss="+25%"
// headerText="Marketing Expense"
// products={[
//   { count: 3, name: "Mobile" },
//   { count: 5, name: "Shirts" },
//   { count: 10, name: "Watch" },
//   { count: 23, name: "Computer" },
// ]}
