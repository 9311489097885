import { TableCell, TableRow } from '@mui/material'
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button } from '@mui/material';
// import { RemoveRedEyeOutlined } from '@mui/icons-material/RemoveRedEyeOutlined';

const Image = ({ src }) => {
    return (

        <div className="cellWrapper">
            <img src={src ?? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT00veDW-SlpBDqu7izpkCncMtChzPsamUqwA&usqp=CAU"} alt="" className="image" />
        </div>
    )
}
const Status = ({ type }) => {
    return (

        <div className="cellWrapper">

            <h6 className={`${type === true ? "approved" : "pending"}`}>{type === true ? "Approved" : "Pending"}</h6>
        </div>
    )
}
const Cells = ({ item, valuesData, actions, pendingButton, setSelectedRow, onDelete, fn_handleUpdate, deleteUrl, modalID }) => {
    function checkValue(value, type) {

        if (value === 'image') {
            return <Image src={type} />
        } else if (value === 'status') {
            return <Status type={type} />
        } else {
            return item[value]
        }

    }

    return (

        <TableRow className="tableRow" key={item?.id}>

            {
                valuesData?.v1 && <TableCell className="tableCell">
                    {checkValue(valuesData?.v1, item[valuesData?.v1])}
                </TableCell>
            }
            {valuesData?.v2 && <TableCell className="tableCell">
                {checkValue(valuesData?.v2, item[valuesData?.v2])}
            </TableCell>
            }
            {valuesData?.v3 && <TableCell className="tableCell">
                {checkValue(valuesData?.v3, item[valuesData?.v3])}
                {/* {bills_val?.v3 === "image" && <Image />}
                {bills_val?.v3 === "status" ? <Status /> : item[bills_val?.v3]} */}

            </TableCell>
            }
            {valuesData?.v4 && <TableCell className="tableCell">
                {checkValue(valuesData?.v4, item[valuesData?.v4])}
            </TableCell>
            }
            {valuesData?.v5 && <TableCell className="tableCell">
                {checkValue(valuesData?.v5, item[valuesData?.v5])}
            </TableCell>
            }
            {actions?.length && <TableCell className="tableCell">
                {pendingButton && <Button variant="contained" size='small' color="primary"
                    onClick={() => fn_handleUpdate(item)}>Approve</Button>}
                {/* actions */}
                {actions?.includes("update") && <span style={{ cursor: "pointer" }}
                    onClick={() => setSelectedRow(item)}
                >
                    <EditIcon color="primary" fontSize="small"
                        data-bs-toggle="modal"
                        data-bs-target={"#" + modalID}
                    />
                </span>}
                {actions?.includes("view") && <span style={{ cursor: "pointer" }}
                    onClick={() => onDelete(item.id, deleteUrl + item.id)}
                >
                    {/* <RemoveRedEyeOutlined color="secondary" fontSize="small" /> */}
                </span>}
                {actions?.includes("delete") && <span style={{ cursor: "pointer" }}
                    onClick={() => onDelete(item.id, deleteUrl + item.id)}
                >
                    <DeleteIcon color="secondary" fontSize="small" />
                </span>}

            </TableCell>
            }

        </TableRow >

    )
}

export default Cells