
import "./style.css";
import AdUnitsIcon from "@mui/icons-material/AdUnits";
import BigCard from "../bigCard/BigCard";
import Swiper from "../../slider/Slider";
import { cardData } from "../../../data/products";
import MediumCard from "../mediumCard/MediumCard";
// import useFetcher from "../../../hooks/useFetcher";
const CardSecondRow = ({ data }) => {
  // const CardSecondRow = () => {
  // const data = useFetcher('internet/slider/get')
  return (
    <>
      <div className="col-lg-12">
        <div className="row">

          <div className="col-md-12 col-lg-6">


            <Swiper
              data={data}
              children={BigCard}

            />
          </div>
          <div className="col-md-12 col-lg-6">


            <Swiper
              data={cardData}
              children={MediumCard} />
          </div>
          {/* <div className="col-md-12 col-lg-6">
            <div className="row">
              <div className="col-md-6">
                <div
                  className="card_div"
                  style={{ borderRadius: "0.3rem", padding: "1rem", marginBottom: "1rem", height: "100%" }}
                >
                  <div className="boxOne">
                    <div>
                      <p>Total Visits</p>
                      <p style={{ color: "lightgreen" }}>+18.4%</p>
                    </div>
                    <div>
                      <h5>42.5k</h5>
                    </div>
                  </div>
                  <div className="boxTwo">
                    <div>
                      <p>
                        <AdUnitsIcon style={{ fontSize: "1rem", color: "blue" }} />
                        Mobile
                      </p>

                      <span className="line">vs</span>
                      <p>
                        <AdUnitsIcon style={{ fontSize: "1rem", color: "orange" }} />
                        Desktop
                      </p>
                    </div>
                    <div>
                      <span>
                        <h4>23.5%</h4>
                        <p>2,890</p>
                      </span>
                      <span>
                        <h4>23.5%</h4>
                        <p>2,890</p>
                      </span>
                    </div>
                  </div>
                  <div className="boxThree">
                    <div></div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card_div" id="main" style={{ padding: "1rem" }}>
                  <div className="boxOne">
                    <div style={{ marginTop: "1.3rem" }}>
                      <h5>Sales this Month</h5>
                    </div>
                  </div>
                  <div className="boxTwo">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p>Total Sales This Month</p>
                      <h5>$28,450</h5>
                    </div>
                  </div>
                  <div className="boxThree_second">
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

    </>
  );
};

export default CardSecondRow;
