import { Button } from '@mui/material'
import StaffCard from '../../components/card/staffCard/StaffCard'
import useFetcher from '../../hooks/useFetcher'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { fn_axiosPut } from '../../hooks/useAxios'
import Details from '../../components/detail/Detail'
import { useParams } from 'react-router-dom'
const Verification = () => {
    const data = useFetcher("internet/user/get")
    const { id } = useParams()
    const [verifiedID, setVerifiedID] = useState([])
    const singleUser = data.filter((item) => item.registerAs === 'user' && item.id === parseInt(id))?.[0]

    async function fn_verify(phone, userId) {
        try {
            await fn_axiosPut("internet/user/updatepass", { status: true, phone })
            setVerifiedID(prev => [...prev, userId])
            toast.success("Approved")
        } catch (error) {
            toast.error("Error")
        }
    }
    return (
        <div className='container-fluid'>

            <div className='row'>

                <Details onClick={fn_verify} verifiedID={verifiedID} singleUser={singleUser} />



            </div>
        </div>

    )
}

export default Verification