import "./style.css";

const CardItems = ({ itemNo, title }) => {
  return (
    <div className="col-6">
      <div className="logobox">
        <div className="logo_items">
          <span>{itemNo}</span>
        </div>
        <div>
          <h6 className="heading d-flex align-items-center">{title}</h6>
        </div>
      </div>
    </div>
  );
};

export default CardItems;
