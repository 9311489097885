import CardBody from "../cardBody/CardBody";
import CardHeader from "../cardHeader/CardHeader";
import "./style.css";
import Button from "@mui/material/Button";
const MediumCard = ({ title, subTitle, profitLoss, headerText, products, hideIcon, img }) => {
  return (
    <section className="mediumCard_wrapper">
      <CardHeader hideIcon={hideIcon} title={title} subTitle={subTitle} profitLoss={profitLoss} />
      <CardBody headerText={headerText} products={products} img={img} />
      <footer className="cardMedium_footer">
        <Button variant="outlined" size="small">
          DETAIL
        </Button>
        <Button color="primary" variant="contained" size="small">
          REPORT
        </Button>
      </footer>
    </section>
  );
};

export default MediumCard;
