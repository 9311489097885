import Sidebar from './sidebar/ChatSidebar'
import Chat from './chat/Chat'
import { useState } from 'react'

const Home = ({ data }) => {
    const [singleUser, setSingleUser] = useState(null)
    return (
        <div className='home' >
            <div className="container" style={{ borderRadius: "0.8rem" }}>
                <Sidebar data={data} setSingleUser={setSingleUser} />
                <Chat singleUser={singleUser} />
            </div>
        </div>
    )
}

export default Home