import { createContext, useCallback, useState } from "react"
import { fn_axiosPut } from "../hooks/useAxios"
export const AppContext = createContext()
export const AppContextProvider = ({ children }) => {
    const [selectData, setSelectData] = useState("")
    const [data, setData] = useState({})

    function fn_setData(e) {
        setSelectData(e.target.value)
    }

    return (
        // <AppContext.Provider value={{ state, setState }}>
        <AppContext.Provider value={{
            selectData, setSelectData,
            fn_setData,
            data, setData,

        }}>
            {children}
        </AppContext.Provider>
    )
}

