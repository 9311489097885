export const staffFields = {
    phone: "",
    registerAs: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    status: true,
}
export const userFields = {
    phone: "",
    registerAs: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    status: "",
}


export const shopFields = {
    title: "",
    duration: "",
    description: "",
    price: "",
    discount: "",
}



export const expenseFields = {
    title: "",
    expenses: "",
    description: "",
}



export const packageFields = {
    title: "",
    duration: "",
    bonusDays: "",
    description: "",
    price: "",
    discount: ""
}

//update fields
export const updateStaffFields = {
    phone: "",
    registerAs: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    status: "",
}
export const updateUserFields = {
    phone: "",
    registerAs: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    status: "",
}
export const updateShopFields = {
    title: "",
    duration: "",
    description: "",
    price: "",
    discount: "",
}
export const updatePackageFields = {
    title: "",
    duration: "",
    bonusDays: "",
    description: "",
    price: "",
    discount: ""
}
export const updateTicketFields = {
    assignId: "",
}
export const updatePaymentFields = {
    status: "",
}