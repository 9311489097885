import './style.css'
const Card = ({ title, count, icon: Icon, background, color }) => {
    return (
        <div className='card_wrap col-12 col-sm-6 col-md-4' >
            <div>
                <h6 className='fw-normal' style={{ color: "gray" }}>{title}</h6>
                <h4 className='fw-bold' style={{ color: "gray" }}>{count}</h4>
            </div>
            <div style={{ background: background, color: color ?? "gray" }}>
                <Icon />
            </div>
        </div>
    )
}

export default Card