import { useCallback, useState } from "react"
import { fn_axiosPost, fn_axiosPut } from "../../hooks/useAxios"
import toast from 'react-hot-toast';

const useFormFn = (inputData, setInputData, url, method, noFormData, picField, type) => {
    const [isLoading, setIsLoading] = useState(false)
    const fn_handleInputData = useCallback((e) => {
        const { name, value } = e.target
        console.log(name);
        type ? setInputData((prev) => ({ ...prev, [name]: value, type }))
            : setInputData((prev) => ({ ...prev, [name]: value }))

    }, [inputData])

    async function fn_handleSubmitForm(e) {
        e.preventDefault()
        setIsLoading(() => true)
        try {
            const formData = new FormData()
            // if (selectData) {

            //     await axios.put(API_URL + "internet/ticket/update/" + inputData.id, {
            //         assignId: selectData
            //     })

            // }
            // else 

            console.log(inputData);

            if (noFormData) {
                if(picField){
                    picField?.map((name) => formData.append(name, e.target?.[name]?.files[0]))
                }
                for (let key in inputData) { formData.append(key, inputData[key]) }
                console.log(formData);
                method === 'post' ? await fn_axiosPost(url, formData) : await fn_axiosPut(url, formData)
            }
            else {
                method === 'post' ? await fn_axiosPost(url, inputData) : await fn_axiosPut(url, inputData)
            }
            toast.success("Inserted successfully")

        } catch (error) {
            console.log(error);
            toast.error("Something went wrong")
        } finally {
            setIsLoading(() => false)
        }
    }

    return {
        isLoading,
        fn_handleInputData,
        fn_handleSubmitForm
    }
}

export default useFormFn