import "./style.css";
const CardBody = ({ products, headerText, img }) => {
  return (
    <div className="card_bodyWrapper">
      <div>
        <div>
          <img width="90" height="90" alt="" src={require("../../../images/logo.png")} />
        </div>
      </div>
      <div>
        <h6>{headerText}</h6>
        <div className="cardBody_items">
          <div className="row">
            {products?.map((item, i) => {
              return (
                <div className="col-md-6" key={i}>
                  <span>{item.count}</span>
                  <span>{item.name}</span>
                </div>
              );
            })}
            {/* <div className="col-md-6">
              <span>1</span>
              <span>Operating</span>
            </div>
            <div className="col-md-6">
              <span>1</span>
              <span>Operating</span>
            </div>
            <div className="col-md-6">
              <span>1</span>
              <span>Operating</span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardBody;
