// import "./App.css";
// import { useState } from "react";
import Login from "./pages/auth/login/Login";
import HomePage from "./pages/homepage/HomePage";
import { Navigate, Route, Routes } from "react-router-dom";
import PageOne from "./pages/dashboard/pageOne/PageOne";
// import changeThemeFn from "./theme/Theme";
import Layout from "./components/layout/Layout";
import AddStaff from "./components/staff/add_staff/AddStaff";
import ViewStaff from "./pages/staff/ViewStaff";
import Tickets from "./pages/tickets/Ticket";
import AddPakages from "./pages/pakages/AddPakages";
import Payments from "./pages/payments/Payments";
// import AllPackages from "./pages/pakages/AllPackages";
import Shop from "./pages/shop/Shop";
import Users from "./pages/users/Users";
import Orders from "./pages/orders/Orders";
import Bills from "./pages/bills/Bills";
import Slider from "./pages/slider/Slider";
import Verification from "./pages/verify/Verification";
import VerificationDetail from "./pages/verify/VerificationDetail";
import Messages from "./pages/messages/Messages";
import Notification from "./pages/notification/Notification";
import { useEffect, useState } from "react";
import Expenses from "./pages/expenses/Expenses";




function App() {
  // const [theme, setTheme] = useState(false);
  const [user, setUser] = useState(false)

  // useEffect(() => {
  //   changeThemeFn(theme);
  // }, [theme]);

  useEffect(() => {
    setUser(localStorage.getItem('user'))
  }, [])

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route element={<Layout />}>
          {/* <Route element={<Layout />}> */}
          <Route path="/home" element={<HomePage />} />
          <Route path="/staff" element={<AddStaff />} />
          <Route path="/staff/:staffId" element={<ViewStaff />} />
          <Route path="/users" element={<Users />} />
          <Route path="/packages" element={<AddPakages />} />
          <Route path="/payment" element={<Payments />} />
          <Route path="/ticketSupport" element={<Tickets />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/bills" element={<Bills />} />
          <Route path="/expenses" element={<Expenses />} />
          <Route path="/slider" element={<Slider />} />
          <Route path="/messages" element={<Messages />} />
          <Route path="/verification" element={<Verification />} />
          <Route path="/verification/:id" element={<VerificationDetail />} />
          <Route path="/notify" element={<Notification />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
