import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { HashRouter } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import { AppContextProvider } from './context/AppContextProvider'
import { ToasterProvider } from './context/ToastProvider'
import TestTable from '../src/components/testTable/TestTable'
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <HashRouter>
    <AppContextProvider>
      <ToasterProvider />
      <App />
      {/* <TestTable /> */}
    </AppContextProvider>
  </HashRouter>
  // </React.StrictMode>
);
