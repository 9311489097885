
import './tableHead.css'

const TableHeader = () => {
    return (
        <div className='tableHead_wrapper'>
            <div>
                <h4>User's Projects List</h4>
            </div>
            <div>
                <label>Search: </label>
                <input type='text' placeholder="Search Projects" />
            </div>

        </div>
    )
}

export default TableHeader