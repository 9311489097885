import SideButtons from "../sidebar_buttons/SideButtons";
import CottageIcon from '@mui/icons-material/Cottage';
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import BackpackIcon from "@mui/icons-material/Backpack";
import PaidIcon from "@mui/icons-material/Paid";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import SmsIcon from "@mui/icons-material/Sms";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from '@mui/icons-material/Notifications';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TuneIcon from '@mui/icons-material/Tune';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import "./style.css";
import { Link } from "react-router-dom";
import { useState } from "react";
const SidebarLayout = ({ toggleMenu, setToggleMenu }) => {
  const [name, setName] = useState('Dashboard')
  return (
    <div>
      <div className="logo_box">
        <span style={{ display: "flex", alignItems: "center" }}>
          <Link
            to="/home"
            style={{ textDecoration: "none", color: "var(--text-gray-light)", display: "flex", alignItems: "center" }}
          >
            <CottageIcon style={{ marginRight: "0.4rem", marginLeft: "auto" }} />
            <span>Connect-Premier</span>
          </Link>
        </span>
        {toggleMenu && (
          <CloseIcon
            onClick={() => setToggleMenu(!toggleMenu)}
            style={{ color: "var(--text-gray-light)", fontSize: "1.3rem", cursor: "pointer" }}
          />
        )
        }
      </div>

      <div>
        <SideButtons title="Dashboard" icon={CottageIcon} linkName="home" id='1' name={name} setName={setName} />
        <SideButtons title="Staff" icon={SupportAgentIcon} linkName="staff" id='32111' name={name} setName={setName} />
        <SideButtons title="Users" icon={PersonIcon} linkName="users" id='333' name={name} setName={setName} />
        <SideButtons title="Verification" icon={VerifiedUserIcon} linkName="verification" name={name} setName={setName} />
        <SideButtons title="Shop" icon={ShoppingCartIcon} linkName="shop" name={name} setName={setName} />
        <SideButtons title="Packages" icon={BackpackIcon} linkName="packages" name={name} setName={setName} />
        <SideButtons title="Payment" icon={PaidIcon} linkName="payment" name={name} setName={setName} />
        <SideButtons title="Ticket Support" icon={AirplaneTicketIcon} linkName="ticketSupport" name={name} setName={setName} />
        <SideButtons title="Orders" icon={AccountBalanceWalletIcon} linkName="orders" name={name} setName={setName} />
        <SideButtons title="Bills" icon={CreditCardIcon} linkName="bills" name={name} setName={setName} />
        <SideButtons title="Expenses" icon={PaidIcon} linkName="expenses" name={name} setName={setName} />
        <SideButtons title="Slider" icon={TuneIcon} linkName="slider" name={name} setName={setName} />
        <SideButtons title="SMS" icon={SmsIcon} linkName="messages" name={name} setName={setName} />
        <SideButtons title="Notification" icon={NotificationsIcon} linkName="notify" name={name} setName={setName} />




        {/* <Arcodian
          fontSize
          list={["list1", "list2"]}
          background
          border
          icon={<SmsIcon style={{ marginRight: "1rem", color: "var(--text-gray-light)", opacity: 0.8 }} />}
          id="collapseNine"
          title="SMS"
          linkName="messages"
        /> */}
      </div>
    </div>
  );
};

export default SidebarLayout;
