import "./style.css";
import BarChartIcon from "@mui/icons-material/BarChart";

// import SmallCard from "../smallCard/SmallCard";
import CardButtons from "../cardButtons/CardButtons";
import PersonIcon from '@mui/icons-material/Person';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import PaidIcon from '@mui/icons-material/Paid';
import DoneIcon from '@mui/icons-material/Done';
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket';
import ShopTwoIcon from '@mui/icons-material/ShopTwo';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import Card from "../../newCard/Card";
import { fn_axiosGet } from '../../../hooks/useAxios'
import { useEffect, useState } from "react";
const CardRow = () => {
  const [data, setData] = useState({})
  const urls = ["internet/topup/get", "internet/user/get", "internet/ticket/get", "internet/buy/get", "internet/buy/get"]
  const promisesArr = urls.map(url => fn_axiosGet(url))
  const fn_filterData = (arr, hasFilter, filterAs, filterVal, hasEarnings) => {
    if (hasFilter) {
      return arr?.filter(item => item[filterAs] === filterVal)?.length
    }
    else if (hasEarnings) {
      return arr?.filter(item => item[filterAs] === filterVal)?.reduce((acc, item) => {
        return acc + parseInt(item.expense)
      }, 0)
    }
    else {
      return arr?.length
    }
  }
  useEffect(() => {
    async function fn_ApiData() {
      try {

        await Promise.all(promisesArr).then((values) => {

          setData({
            paymentMethod: values[0]?.data?.data,
            users: values[1]?.data?.data,
            tickets: values[2]?.data?.data,
            orders: values[3]?.data?.data,
            bills: values[4]?.data?.data,
          })
        })

      } catch (error) {
        console.log("error");
      }
    }
    fn_ApiData()

  }, [])
  return (
    <>
      <div className="col-lg-12" style={{ marginBottom: "1rem" }}>
        <div
          className="l-bg-orange-dark"
          style={{
            position: "relative",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: "0.3rem",
            overflow: "hidden",
          }}
        >
          <div className="card-statistic-3 p-4">
            <div className="card-icon card-icon-large">
              <i className="fas fa-dollar-sign" />
            </div>
            <div className="mb-4">
              <h5 className="card-title_big mb-1">Dashboard Overview</h5>
              {/* <p className="mb-0 card_paragraph">
                Total 42.5k Sales <span>+18%</span>
              </p> */}

            </div>
            <div className="row card_btns align-items-center mb-2 d-flex" >
              <Card title="Total Users" count={fn_filterData(data?.users, true, "registerAs", "user")} icon={PersonIcon} background="blueviolet" color="#fff" />
              <Card title="Total Staff" count={fn_filterData(data?.users, true, "registerAs", "staff")} icon={SupportAgentIcon} background="blueviolet" color="#fff" />
              <Card title="Unpaid Customers" count={fn_filterData(data?.bills, true, "status", false)} icon={PersonIcon} background="blueviolet" color="#fff" />
              <Card title="Paid Customers" count={fn_filterData(data?.bills, true, "status", true)} icon={DoneIcon} background="blueviolet" color="#fff" />
              <Card title="Ticket Support" count={fn_filterData(data?.tickets, false)} icon={AirplaneTicketIcon} background="blueviolet" color="#fff" />
              <Card title="Resolve Support" count={fn_filterData(data?.tickets, true, "status", "approved")} icon={SupportAgentIcon} background="blueviolet" color="#fff" />
              <Card title="This Month Earnings" count={"Rs " + fn_filterData(data?.orders, false, "type", "monthly", true)} icon={AccountBalanceIcon} background="blueviolet" color="#fff" />
              {/* <Card title="New Connections" count="4" icon={BarChartIcon} background="blueviolet" color="#fff" /> */}
              {/* <Card title="Pending Connections" count="1" icon={BarChartIcon} background="blueviolet" color="#fff" /> */}
              <Card title="New Orders" count={fn_filterData(data?.orders, false)} icon={PaidIcon} background="blueviolet" color="#fff" />
              <Card title="Pending Orders" count={fn_filterData(data?.orders, true, "status", false)} icon={ShopTwoIcon} background="blueviolet" color="#fff" />
              <Card title="Pending Verifications" count={data?.users?.filter((item) => item.registerAs === "user" && item.status === false)?.length} icon={DoneIcon} background="blueviolet" color="#fff" />
              <Card title="Due Balance" count={fn_filterData(data?.bills, true, "status", false)} icon={BarChartIcon} background="blueviolet" color="#fff" />
              <Card title="Bill Payment" count={fn_filterData(data?.bills, true, "status", true)} icon={AccountBalanceWalletIcon} background="blueviolet" color="#fff" />


            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6">
        <div style={{ marginBottom: "1rem" }}>
          <div className="row">
            {/* <div className="col-md-6">
              <SmallCard cardOne />
            </div> */}
            {/* <div className="col-md-6">
              <SmallCard cardTwo />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardRow;
