import React from "react";
import "./style.css";
import Card from "../card/Card";
const Rightbar = () => {
  return (
    <div className="rightbar_wrapper" style={{ height: "250vh" }}>
      <Card />
    </div>
  );
};

export default Rightbar;
