import CardItems from "./cardItems/CardItems";
import "./style.css";

const BigCard = ({ title, subTitle, headerText, profitLoss, products }) => {
  return (
    <div className="slide_div"

    >
      <div className="l-bg-orange-dark" style={{ position: "relative", overflow: "hidden" }}>
        <div className="card-statistic-3 p-4">
          <div className="card-icon card-icon-large">
            <i className="fas fa-dollar-sign" />
          </div>
          <div className="mb-4">
            <h5 className="card-title mb-1">{title}</h5>
            <p className="mb-0 card_subtitle">
              {subTitle}
              <span>{profitLoss}</span>
            </p>
          </div>
          <div className="row align-items-center mb-2 d-flex">
            <div className="col-12">
              <p>{headerText}</p>
            </div>
            {products?.map((item) => (
              <CardItems key={item} itemNo={item.count} title={item.name} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BigCard;
