import StaffCard from "../../components/card/staffCard/StaffCard";
import { useParams } from "react-router-dom";
import useFetcher from "../../hooks/useFetcher";
import StaffProjects from "../../components/staff/staffProjects/StaffProjects";

const ViewStaff = () => {
  const { staffId } = useParams();



  const singleStaff = useFetcher("internet/user/get/" + staffId)




  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12 col-md-5">
          <div style={{ height: "auto", paddingBottom: "1.5rem" }}>
            <StaffCard
              name={singleStaff?.firstName + " " + singleStaff?.lastName}
              img={singleStaff?.selfie || require('../../images/human.png')}
              email={singleStaff?.email}
              status={singleStaff?.status ? "Active" : "InActive"}
              role={singleStaff?.registerAs || "User"}
              contact={singleStaff?.phone}
            />

          </div>

        </div>
        {/* <div className="col-md-8">
          <StaffProjects />
        </div> */}
      </div>
    </div>
  );
};

export default ViewStaff;
