import React from 'react'
import Chart from "react-apexcharts";

const BasicChart = () => {

  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: ["JAN", "FEB", "MARCH", "APRIL", "MAY", "JUNE", "JULY", "AUG", "SEP", "OCT", "NOV", "DEC"]
    },

  }


  const series = [
    {
      name: "series-1",
      data: [30, 40, 45, 50, 49, 60, 70, 91, 100, 110, 115, 120],
      colors: 'blueviolet',
    }
  ]



  return (
    <div>
      <div className="row">
        <div className="mixed-chart">
          <div className="mb-4" style={{ marginLeft: "1.2rem" }}>
            <h5 className="card-title_big mb-1">Sales Overview</h5>
            <p className="mb-0 card_paragraph">
              Total 42.5k Sales <span>+18%</span>
            </p>

          </div>
          <Chart
            options={options}
            series={series}
            type="line"
            width="100%"
            height=" 350"
          />
        </div>
      </div>

    </div>
  )
}

export default BasicChart
