import "./style.css";
import { DataGrid } from "@mui/x-data-grid";
import { useState } from "react";
import Modal from "../../modal/Modal";
import TableActions from "./actions/TableActions";
import useFormFn from "../../modalRight/functions";
import useFetcher from "../../../hooks/useFetcher";
import useSearch from "../../../hooks/useSearch";
import TableHeader from "../../Table/header/TableHeader";
import Card from "../../newCard/Card";
import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";



const Datatable = ({
    title, titleSecond, titleThird, modalTitle, id, setToggleModal, tabNumber, setTabNumber,
    tabList,
    url, getUrl, deleteUrl, method, noFormData, tableColumns, searchResult,
    hasActions, actionType, height, navigateUrl,
    fieldsToUpdate,
    updateFieldsArr,
    filterData,
    filterAs, cardData

}) => {
    const [inputData, setInputData] = useState(fieldsToUpdate)
    const [singleRow, setSingleRow] = useState({});

    const { fn_handleInputData, fn_handleSubmitForm } = useFormFn(inputData, setInputData, url + singleRow?.id, method, noFormData)
    const data = useFetcher(getUrl)
    const tableRowsArr = filterData ? data.filter((item) => item.registerAs === filterAs) : data
    const { fn_deleteData, splicedArr } = useSearch(tableRowsArr, '')


    const actionColumn = hasActions ? [
        {
            field: "action",
            headerName: "Action",
            width: 200,
            renderCell: (params) => {
                return <TableActions
                    params={params}
                    navigateUrl={navigateUrl}
                    deleteUrl={deleteUrl}
                    fn_handleDelete={fn_deleteData}
                    setSingleRow={setSingleRow}
                    actionType={actionType}
                    id={id}
                />;
            },
        },
    ] : [];


    return (
        <div>
            <div className="row" style={{ marginLeft: "0" }}>

                {cardData?.[0] && <Card title={cardData[0]?.title} count={tableRowsArr.filter(item => item.registerAs === filterAs).length} icon={ArrowOutwardIcon} background={cardData[0]?.background}
                    color={cardData[0]?.color} />
                }



            </div>
            <TableHeader
                actionLabel="Create"
                hasTabs={true}
                tabNumber={tabNumber}
                setTabNumber={setTabNumber}
                setToggleModal={setToggleModal}
                tabList={tabList}
                title={title}
                titleSecond={titleSecond}
                titleThird={titleThird}
            />
            <div className="datatable">
                <DataGrid
                    className="datagrid"
                    rows={searchResult || (splicedArr ?? tableRowsArr)}
                    columns={tableColumns.concat(actionColumn)}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                    style={{ height: height }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
            <Modal
                id={id}
                title={modalTitle}
                // modalName={modalName}
                updateInputArr={updateFieldsArr}
                data={singleRow}
                fieldsToUpdate={updateFieldsArr}
                onChange={fn_handleInputData}
                onSubmit={(e) => fn_handleSubmitForm(e, inputData)}
            />

        </div>
    );
};

export default Datatable;
