import Table from "../pakages/AllPackages";
import { ticketColumns } from "../../data/columns";
import { updateTicket } from '../../data/inputData'
import useFetcher from "../../hooks/useFetcher";
import { updateTicketFields } from "../../data/inputFields";

const AcceptTicket = () => {
    const data = useFetcher("internet/user/get")
    const filterStaff = data.filter(item => item.registerAs === 'staff')

    return (
        <div style={{ padding: "1rem" }}>

            <div className="col-12 mt-3">
                <div className="col-12 mt-3">
                    <Table
                        title="All Tickets"
                        modalTitle="Update Tickets"
                        modalData={filterStaff}
                        url="internet/ticket/get"
                        columns={ticketColumns}
                        id="ticketModal"
                        category="ticket"
                        updateUrl="internet/ticket/update/"
                        deleteUrl="internet/ticket/delete/"
                        method="put"
                        noFormData={true}
                        updateInputArr={null}
                        fieldsToUpdate={updateTicketFields}
                        filterAs={[undefined, 'pending', 'approved']}
                        filterBy={[undefined, 'status', 'status']}
                        actions={['update']}
                        cardData={[
                            { title: "Total Tickets", background: "blueviolet", color: "#fff" },
                            { title: "Pending Tickets", background: "blueviolet", color: "#fff" },
                            { title: "Resolved Tickets", background: "blueviolet", color: "#fff" }
                        ]

                        }
                    />
                </div>
            </div>
        </div>

    )
}

export default AcceptTicket