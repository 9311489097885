import { IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import "./style.css";
const CardHeader = ({ title, subTitle, profitLoss, hideIcon }) => {
  return (
    <div className="card_topHeader">
      <div>
        <h5>{title}</h5>
        {!hideIcon ? (
          <IconButton>
            <MoreVertIcon />
          </IconButton>
        ) : (
          <span>
            {profitLoss} <KeyboardArrowUpIcon fontSize="small" style={{ marginBottom: "0.1rem" }} />
          </span>
        )}
      </div>
      <p>
        {subTitle}
        {!hideIcon && (
          <span>
            {profitLoss} <KeyboardArrowUpIcon fontSize="small" style={{ marginBottom: "0.1rem" }} />
          </span>
        )}
      </p>
    </div>
  );
};

export default CardHeader;
